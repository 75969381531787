import Vue from 'vue';
import { Location } from 'vue-router';
import { CustomSettings } from './HHotelToBookingAction';
import {
  HotelBasicInfo,
  HotelDetail,
  HotelDynamicPackage,
  HotelBrandBasicInfo,
  BookingActionSetting,
  BOOKING_ACTION_BUTTON_SETTINGS_MAP,
} from '~/schemes';

export function toInfo(
  vm: Vue,
  condition: {
    hotel?: HotelBasicInfo | HotelDetail;
    brand?: HotelBrandBasicInfo;
  },
) {
  let to: Location | undefined;
  let linkAttrs: { [key: string]: any };
  let externalBookingSite: string | null | undefined;
  let dynamicPackage: Partial<HotelDynamicPackage> = {};

  /**
   * 空室検索リンクか外部予約URLがあるか
   */
  let hasRoomSearchLink: boolean;

  /**
   * 空室検索リンクか外部予約URLかダイナミックパッケージ系リンクがあるか
   */
  let hasBookingLink: boolean;

  /**
   * 最低価格があるか
   */
  let hasLowestPrice: boolean;

  const { hotel, brand } = condition;

  if (hotel) {
    const { ygetsId, externalBookingSite: external } = hotel;
    let _ygetsDetected = false;
    let { lowestPrice } = hotel;
    if ('_ygetsDetected' in hotel) {
      _ygetsDetected = hotel._ygetsDetected;
    }
    if (!_ygetsDetected) {
      lowestPrice = undefined;
    }

    externalBookingSite = external;

    linkAttrs = external
      ? {
          href: external,
          target: '_blank',
        }
      : {};

    // 宿GETS IDがあれば空室検索ボタンを表示する
    to =
      ygetsId && !linkAttrs.href
        ? vm.$language.link(`/hotels/${hotel.slug}/roomsearch`)
        : undefined;

    if ('dynamicPackage' in hotel) {
      dynamicPackage = hotel.dynamicPackage || {};
    }

    hasRoomSearchLink = !!external || !!to;
    hasBookingLink = hasRoomSearchLink || !!dynamicPackage.air;
    hasLowestPrice = lowestPrice != null;
  } else if (brand) {
    hasRoomSearchLink = true;
    hasBookingLink = true;
    let href = `${vm.$language.crossSearchUrl()}/`;
    const crossSearchId = brand.crosssearchId;
    if (crossSearchId) {
      href += `?brands=${crossSearchId}`;
    }

    linkAttrs = {
      href,
      target: '_blank',
    };

    const hotels = vm.$hotel.hotelsByBrandId(brand.id);
    hasLowestPrice = hotels.some(
      (hotel) => hotel.lowestPrice != null && hotel.lowestPrice > 0,
    );
  } else {
    hasRoomSearchLink = true;
    hasBookingLink = true;
    hasLowestPrice = false;

    linkAttrs = {
      href: `${vm.$language.crossSearchUrl()}/`,
      target: '_blank',
    };
  }

  let evAction = '';
  if (process.browser) {
    if (to) {
      evAction = location.origin + vm.$router.resolve(to).resolved.fullPath;
    } else if (externalBookingSite) {
      evAction = externalBookingSite;
    }
  }

  return {
    to,
    hasRoomSearchLink,
    hasBookingLink,
    linkAttrs,
    hasLowestPrice,
    externalBookingSite,
    evAction,
    dynamicPackage,
  };
}

export function getCustomBookingActionSetting(
  bookingActionSetting: BookingActionSetting | null,
): CustomSettings {
  if (!bookingActionSetting) {
    return {
      customUrl: undefined,
      customStyle: undefined,
      customText: undefined,
    };
  }
  return {
    customUrl:
      (bookingActionSetting.link && bookingActionSetting.link.url) || undefined,
    customStyle:
      (bookingActionSetting.color && {
        backgroundColor:
          BOOKING_ACTION_BUTTON_SETTINGS_MAP[bookingActionSetting.color]
            .bgColor,
        color:
          BOOKING_ACTION_BUTTON_SETTINGS_MAP[bookingActionSetting.color].color,
        borderColor:
          BOOKING_ACTION_BUTTON_SETTINGS_MAP[bookingActionSetting.color]
            .bgColor,
      }) ||
      undefined,
    customText:
      (bookingActionSetting.link && bookingActionSetting.link.text) ||
      undefined,
  };
}
