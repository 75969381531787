import './HAllBrandsNavigation.scss';

import Vue from 'vue';
import * as tsx from 'vue-tsx-support';
import { Component } from 'vue-property-decorator';
import { ComputedBrand } from './schemes';
import { createBrandLinks } from './helpers';
import { HBrandNavigation } from './HBrandNavigation';
import { HRegionNavigation } from './HRegionNavigation';

export interface HAllBrandsNavigationProps {}

export interface HAllBrandsNavigationEmits {}

export interface HAllBrandsNavigationScopedSlots {}

@Component<HAllBrandsNavigationRef>({
  name: 'HAllBrandsNavigation',
  render() {
    const { brands } = this;

    return (
      <div staticClass="h-all-brands-navigation">
        <h2 staticClass="h-all-brands-navigation__title">
          {this.$i18n.t('label.footer.exploreOurDestinations')}
        </h2>

        {/* PCのみ */}
        <HRegionNavigation staticClass="h-all-brands-navigation__regions__pc" />

        <h3 staticClass="h-all-brands-navigation__title-find-by-brand">
          {this.$i18n.t('label.footer.brands')}
        </h3>
        <div staticClass="h-all-brands-navigation__list">
          {brands.map((brand) => (
            <HBrandNavigation
              key={brand.id}
              staticClass="h-all-brands-navigation__list__item"
              brand={brand}
            />
          ))}
        </div>

        {/* SPのみ */}
        <HRegionNavigation staticClass="h-all-brands-navigation__regions__sp" />
      </div>
    );
  },
})
export class HAllBrandsNavigationRef
  extends Vue
  implements HAllBrandsNavigationProps {
  /** ブランド表示順の定義 */
  private static readonly brandSortOrder = [
    'hoshinoya',
    'kai',
    'risonare',
    'omo',
    'beb',
    'lucy',
    'uniquehotels',
    'daytrip',
  ];

  /**
   * 全ブランド情報
   *
   * * ブランド基本情報にブランド内リンクリストを追加したオブジェクトのリスト
   */
  get brands(): ComputedBrand[] {
    const rawBrands = this.$hotel.brands
      .map((brand) => {
        return {
          ...brand,
          links: createBrandLinks(this, brand),
        };
      })
      .filter((brand) => brand.links.length > 0); // リンクが0件のブランドは表示しない

    // sortOrdersの順番でslugを元にブランドをソート
    const sortedBrands = [...rawBrands].sort((a, b) => {
      const orderA = HAllBrandsNavigationRef.brandSortOrder.indexOf(a.slug);
      const orderB = HAllBrandsNavigationRef.brandSortOrder.indexOf(b.slug);

      // 定義されていない順序は最後に配置
      if (orderA === -1) return 1;
      if (orderB === -1) return -1;

      // 定義順にソート
      return orderA - orderB;
    });

    return sortedBrands;
  }
}

export const HAllBrandsNavigation = tsx
  .ofType<
    HAllBrandsNavigationProps,
    HAllBrandsNavigationEmits,
    HAllBrandsNavigationScopedSlots
  >()
  .convert(HAllBrandsNavigationRef);
