import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ComputedBrandLinkItem } from './schemes';
import './HBrandNavigationListItem.scss';

export interface HBrandNavigationListItemProps {
  item: ComputedBrandLinkItem;
}

export interface HBrandNavigationListItemEmits {}

export interface HBrandNavigationListItemScopedSlots {}

@Component<HBrandNavigationListItemRef>({
  name: 'HBrandNavigationListItemItem',
  render() {
    const { name, prefix, address, status, TagName, props, attrs } = this.item;

    return (
      <TagName
        staticClass="h-brand-navigation-list-item"
        props={props}
        attrs={attrs}>
        <p staticClass="h-brand-navigation-list-item__name">
          {prefix && (
            <span staticClass="h-brand-navigation-list-item__name__prefix">
              {prefix}
            </span>
          )}
          <span>{name}</span>
        </p>
        <p staticClass="h-brand-navigation-list-item__address">{address}</p>
        {status && (
          <div staticClass="h-brand-navigation-list-item__status">{status}</div>
        )}
      </TagName>
    );
  },
})
export class HBrandNavigationListItemRef
  extends Vue
  implements HBrandNavigationListItemProps {
  @Prop({ type: Object, required: true })
  readonly item!: HBrandNavigationListItemProps['item'];
}

export const HBrandNavigationListItem = tsx
  .ofType<
    HBrandNavigationListItemProps,
    HBrandNavigationListItemEmits,
    HBrandNavigationListItemScopedSlots
  >()
  .convert(HBrandNavigationListItemRef);
