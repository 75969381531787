import type Vue from 'vue';
import { LanguageDataMap, AvailableLanguage } from './language';
import { BannerLink } from './general';
import {
  HotelOption,
  Gallery,
  HotelFeature,
  HotelRoom,
  HotelRoomSettings,
  HotelRoomGallery,
  HotelAccess,
  HotelNotification,
  HotelPhotoGroup,
  HotelLocation,
  YgHotelDetail,
  ReservationCenter,
  HotelTodoSettings,
  HotelActivity,
  HotelRestaurant,
  Restaurant,
  CreditCardType,
  HotelBrandInfo,
  HotelFeatureConceptMovie,
  PaymentType,
} from './';
import type { ThemeService } from '~/plugins/theme';
import type {
  HotelFlowLineSettings as CMSHotelFlowLineSettings,
  HotelBannerLink as CMSHotelBannerLink,
  Hyperlink as CMSHyperLink,
  NightRange,
  MediaInfo,
  BusinessStatus,
} from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
export type {
  NightRange,
  BusinessStatus,
} from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

/**
 * what is LS?
 * language 〇〇の略(行さんが思い出したら更新)
 * 名前にLSがついてるinterfaceはフロントエンド層に渡さない
 * なぜなら言語分の情報が含まれているから。
 * 変換かけて必要な言語を出し分ける必要がある
 * */

/** ホテル詳細の問い合わせ先情報interface */
export interface HotelContact {
  /** 名前 */
  name: string;
  /** 電話番号 */
  tel: string;
  /**
   * 受付開始時間
   * HH:mm
   */
  from: string;
  /**
   * 受付開始時間
   * HH:mm
   * */
  to: string;
  /** 注記 */
  memo: string | null;
}

export interface HotelContactLS extends Omit<HotelContact, 'name' | 'memo'> {
  name: LanguageDataMap<string>;
  memo: LanguageDataMap<string | null> | null;
}

export function extractHotelContactLS(
  ls: HotelContactLS,
  lang: AvailableLanguage,
): HotelContact {
  const { name, memo } = ls;
  return {
    ...ls,
    name: name[lang] as string,
    memo: (memo && memo[lang]) || null,
  };
}

export interface HotelAvailableLanguage {
  /** 有効言語 */
  id: AvailableLanguage;
  /** 外部サイトURL */
  external: string | null;
}

export interface HotelBasicInfo {
  /**
   * CMSのエンティティID
   */
  id: string | null;

  /**
   * 画面URLの構成表記となる
   */
  slug: string;

  /**
   * 有効言語リスト
   */
  availableLanguages: HotelAvailableLanguage[];

  /**
   * ブランドID
   */
  brandId: string;

  /**
   * 施設単体名称
   */
  name: string;

  /**
   * 施設正式名称
   */
  fullName: string;

  /**
   * 最低価格（1泊1室）
   * ※ 税込サービス料別
   */
  lowestPrice?: number | null;

  /**
   * 外部予約サイトURL
   */
  externalBookingSite?: string | null;

  /**
   * 宿GETS ID
   */
  ygetsId?: string | null;

  /**
   * 予約導線を非表示にする
   */
  hideBookingLink: boolean;

  /**
   * 施設紹介情報
   */
  introduction: {
    /**
     * キャッチコピー
     */
    catchCopy: string;

    /**
     * 画像
     */
    image: MediaInfo | null;

    /**
     * 紹介文
     */
    text: string | null;

    /**
     * 紹介文(ショート)
     */
    shortText: string | null;
  };
  /**
   * ブランドサイト所在地
   */
  brandAddress?: string;
  /**
   * 基準通貨
   */
  baseRate: string;
  /**
   * 営業状況の識別子
   *
   * この値は営業状況リストのvalueと一致する
   * 表示する際は、vm.$commons.hotelStatuses,find({ value } => value === hotel.status).name とすると良い
   */
  status: string | null;
  /**
   * 営業状況フラグ
   */
  businessStatus: BusinessStatus | null;
}

export interface HotelInfo extends HotelBasicInfo {
  brand: HotelBrandInfo;

  /**
   * 表示テーマ
   */
  theme: string | null;

  /**
   * 施設名（フル英語）
   */
  globalFullName?: string;

  /**
   * 郵便番号
   */
  postcode: string;

  /**
   * 住所
   */
  address: string;

  /**
   * ブランドサイト所在地
   */
  brandAddress?: string;

  /**
   * マップコード
   */
  mapcode: string | null;

  /**
   * 外部地図サービスへのURL
   */
  mapUrl: string;

  /**
   * 埋め込み地図URL
   */
  embedMap?: string | null;

  /**
   * 施設メールアドレス
   */
  email: string;

  /**
   * インスタグラムアカウント
   */
  instagram: string | null;

  /**
   * Twitterアカウント
   */
  twitter: string | null;

  /**
   * Facebookアカウント
   * https://www.facebook.com/●●●
   */
  facebook?: string | null;

  /**
   * Youtubeアカウント
   */
  youtube?: string | null;

  /**
   * favicon画像パス
   */
  favicon: string | null;

  /**
   * 予約センターを利用しないフラグ
   */
  doNotUseReservationCenter: boolean;

  /**
   * 予約センターID
   */
  reservationCenterId?: string | null;

  /**
   * 予約センター
   */
  reservationCenter: ReservationCenter | null;

  /**
   * 電話予約センター注記
   */
  reservationCenterNotice: string | null;

  /**
   * 施設からのお知らせ
   * - 連絡先ページに表示
   */
  hotelInformations?: HotelNotification[];

  /**
   * ダイナミックパッケージ
   * 航空券付きプランや、新幹線付きプラン等の設定
   */
  dynamicPackage: HotelDynamicPackage;

  /**
   * ロゴ画像パス
   */
  logo: {
    /**
     * ロゴ画像（縦詰み）ブラック
     * 180x144
     */
    stack: string;

    /**
     * ロゴ画像（横詰み）ブラック
     * 196x40
     */
    inline: string;

    /**
     * ロゴ画像（縦詰み）ホワイト
     * 180x144
     */
    stackInvert: string;
  };

  chatPlusSetting: string | null;

  /**
   * 施設お知らせの特集
   */
  suggestedTopicLinks?: CMSHyperLink[];

  /**
   * 施設お知らせの特集表示数
   */
  suggestedTopicCount?: number;
}

export enum KeyVisualEffectType {
  Fade = 'fade',
  Snow = 'snow',
}

export interface KeyVisualEffectData {
  Fade: {
    image: string;
    delay: number;
    duration: number;
  };
  Snow: {
    delay: number;
    duration: number;
  };
}

export type KeyVisualEffect =
  | {
      type: KeyVisualEffectType.Fade;
      data: KeyVisualEffectData['Fade'];
    }
  | {
      type: KeyVisualEffectType.Snow;
      data: KeyVisualEffectData['Snow'];
    };

export interface KeyVisualVideoItem {
  /**
   * vimeoのシェアURL
   */
  url?: string;

  /**
   * サムネイルのURL
   */
  thumb?: string;
}

export interface KeyVisualVideo {
  wide?: KeyVisualVideoItem;
  square?: KeyVisualVideoItem;
}

export interface KeyVisualSubImage {
  src: string;
  position?: string;
}

export interface KeyVisual {
  video?: KeyVisualVideo;
  image?: string;
  position?: string;
  effects: KeyVisualEffect[];
  subImages?: KeyVisualSubImage[];
}

export interface HotelAdditionalData {
  html: string | undefined;
  icon?: string | null;
}

export interface HotelDynamicPackage {
  air: string | null;
  shinkansen: string | null;
  rentalCar: string | null;
}

export interface HotelDynamicPackageLS
  extends Omit<HotelDynamicPackage, 'air' | 'shinkansen'> {
  air: LanguageDataMap<string | null> | null;
  shinkansen: LanguageDataMap<string | null> | null;
}

export function extractHotelDynamicPackageLS(
  ls: HotelDynamicPackageLS,
  lang: AvailableLanguage,
): HotelDynamicPackage {
  const { air, shinkansen } = ls;
  return {
    ...ls,
    air: air && (air[lang] as string | null),
    shinkansen: shinkansen && (shinkansen[lang] as string | null),
  };
}

export interface HotelBanner {
  url: string | null;
  text: string;
}

export interface HotelBannerLS extends Omit<HotelBanner, 'url' | 'text'> {
  url: LanguageDataMap<string | null>;
  text: LanguageDataMap<string>;
}

export function extractHotelBannerLS(
  ls: HotelBannerLS,
  lang: AvailableLanguage,
): HotelBanner | null {
  const { url, text } = ls;
  if (!text) return null;
  const _text = text[lang];
  if (!_text) return null;

  return {
    ...ls,
    url: url[lang] || null,
    text: _text,
  };
}

// """exist"" → 会議室あり
// ""not-exist"" → 会議室なし
// ""contact"" → 問い合わせ"
export const HotelConferenceRoomTypes = [
  'not-exist',
  'exist',
  'contact',
] as const;

export type HotelConferenceRoomType = typeof HotelConferenceRoomTypes[number];

export interface HotelConferenceRoom {
  type: HotelConferenceRoomType;
  url: string | null;
  note: string | null;
}

export interface HotelConferenceRoomLS
  extends Omit<HotelConferenceRoom, 'url' | 'note'> {
  url: LanguageDataMap<string | null> | null;
  note: LanguageDataMap<string | null> | null;
}

// // """none"" → なし
// // ""indoor"" → 屋内
// // ""outdoor"" → 屋外"
// export const HotelParkingTypes = ['none', 'indoor', 'outdoor'] as const;

// export type HotelParkingType = typeof HotelParkingTypes[number];

// export interface HotelParking {
//   type: HotelParkingType;
//   charge: number | null;
//   capacity: number | null;
// }

// "allowed" → 可
// "no" → 不可
export const HotelPetsTypes = ['allowed', 'no'] as const;

export type HotelPetsType = typeof HotelPetsTypes[number];

export interface HotelPets {
  type: HotelPetsType;
  note: string | null;
}

export interface HotelPetsLS extends Omit<HotelPets, 'note'> {
  note: LanguageDataMap<string | null> | null;
}

export interface HotelNightsMemo {
  nights: number | NightRange;
  memo: string;
}

export interface HotelNightsMemoLS extends Omit<HotelNightsMemo, 'memo'> {
  memo: LanguageDataMap<string>;
}

export interface HotelInternalDetailInfo {
  location: HotelLocation | null;
}

/**
 *  動線設定
 */
export interface HotelFlowLineSettings
  extends ResolvedLanugageData<CMSHotelFlowLineSettings, AvailableLanguage> {}

export interface HotelDetailInfo extends HotelInternalDetailInfo {
  description: string;
  og: {
    image: string | null;
  };
  notifications: HotelNotification[];
  roomsearchNotifications: HotelNotification[];
  keyVisual: KeyVisual;
  style: string | null;
  leadHTMLDefault: string | null;
  leadHTML: string;
  flowLineSettings: HotelFlowLineSettings;
  additionalData?: HotelAdditionalData[] | null;
  sectionIcons?: string[] | null;
  features: HotelFeature[];
  featureConceptMovie: HotelFeatureConceptMovie | null;
  featureMiniGallery?: string[] | null;
  todo: HotelTodoSettings | null;

  /**
   * @TODO
   * 本当はモジュールリストの中に入れんといけないので、このスキームは良くない
   * 青森屋が急ぎなので暫定対応です
   */
  todoBanner: HotelBanner | null;
  activitySettings: HotelActivity | null;
  restaurantSettings: HotelRestaurant | null;
  restaurants: Restaurant[];

  /**
   * 食事ページで特定の食事処のコンテンツのみ紹介したい場合、その食事処のID
   *
   * * CMS側ではnumberで保存されているがGF側でstringにキャストしちゃっているので、こちらも準じる
   */
  focusedRestaurantId?: string | null;

  rooms: HotelRoom[];
  roomSettings?: HotelRoomSettings;
  roomGallery?: HotelRoomGallery;
  accessSettings?: HotelAccess;

  /** 室数 */
  numOfRooms: number;

  /** 棟数 */
  numOfBuildings: number | null;

  /** 面積（ha） */
  area: number | null;
  spa: HotelOption[];
  kidsRoom: HotelOption[];
  pool: HotelOption[];
  onsen: HotelOption[];
  publicBath: HotelOption[];
  skiing: HotelOption[];
  conferenceRoom: HotelConferenceRoom;
  transfer: string | null;
  parking: string | null;
  wifi: string | null;
  pets: HotelPets;

  /** 記念日・お祝い */
  celebration: string | null;

  /** 子供添い寝 */
  childLying: string | null;

  /** チェックイン時間 */
  checkIn: string;

  /** 最終チェックイン時間 */
  checkInLimit: string | null;

  /** チェックアウト時間 */
  checkOut: string;

  /** 客室定員 */
  roomCapacity: {
    min: number;
    max: number;
  };

  /** 利用可能クレジットカード */
  creditCard: CreditCardType[];

  /** 決済方法 */
  payment: PaymentType[];

  /** 決済方法（クレカ以外） */
  paymentMethods: string | null;

  /** その他、ホテルの特記事項 */
  memo: string[];
  galleries: Gallery[];

  /**
   * 12枚揃わないケースがある。
   * 6枚に満たない場合はエリアとるつめ
   * 12枚に満たない場合は1グループを繰り替えす
   */
  photoGroups: HotelPhotoGroup[];

  /** 空室検索時の宿泊数別の追記 */
  nightsMemos: HotelNightsMemo[];

  /** TOPバナーに追加するお知らせ */
  bannerNotice?: string;

  /** 館内マップ */
  insideMaps: {
    url: string;
    text: string;
  }[];

  /** 問い合わせ先一覧 */
  contacts: HotelContact[];

  /** EV充電器 */
  evCharger: string | null;

  /** ウィディング */
  wedding: string | null;

  /** 消灯時間 */
  lightsOffTime: string | null;

  /** 荷物預かり */
  luggageStorage: string | null;

  /** 飲み水提供 */
  waterService: string | null;

  /** 営業期間 */
  businessPeriod: string | null;
}

export interface HotelDetail extends HotelInfo, HotelDetailInfo {
  /**
   * 宿GETSの施設詳細情報
   */
  ygets: YgHotelDetail | null;

  /**
   * 宿GETSの施設詳細情報の有無の判定が完了しているか
   */
  _ygetsDetected: boolean;

  /**
   * アクセスコンテンツが一つ以上ある場合true
   */
  hasAccessContents: boolean;
}

export interface YgetsableHotelDetail extends Omit<HotelDetail, 'ygets'> {
  ygets: YgHotelDetail;
}

export function isYgetsableHotelDetail(
  source: HotelDetail | YgetsableHotelDetail,
): source is YgetsableHotelDetail {
  return !!source.ygets;
}

/**
 * ホテルバナーリンク
 */
export interface HotelBannerLink
  extends ResolvedLanugageData<CMSHotelBannerLink, AvailableLanguage> {}

/**
 *
 * ホテルバナーリンクをバナーリンクに変換する。
 *
 * - URLやラベルは現在表示された施設に対応したものになる。
 * - 手入力の場合、タイトル・画像・リンクをバナーリンクでコンバートする。
 * - それ以外の場合、画像はそのまま使い、タイトルは自動生成され、リンクはその種別の下層リンクを生成する。
 * - もしnullが返ってきたときはバナーを表示したくない時（下層コンテンツがないときなど）
 *
 * @TODO 下層コンテンツがない時はnullを返す
 *
 * @param hotelBannerLink - ホテルバナーリンク
 * @param vm - Vueのインスタンス
 */
export function normalizeHotelBannerLink(
  hotelBannerLink: HotelBannerLink,
  vm: Vue,
): BannerLink | null {
  const { key, type, title, image, link, pageIds } = hotelBannerLink;
  // 手入力はそのまま送る。そうでない場合はタイトルとかリンク作成
  if (type === 'manualInput') {
    // リンクが未入力ならバナーを表示したくないのでnullをreturnする
    if (link && !link.url) {
      return null;
    }
    return { key, title, image, link, pageIds };
  }

  // リンクを自動生成する
  const hotel = vm.$hotel.current;
  if (!hotel) {
    throw new Error('施設がないのでリンクが生成できません。');
  }

  // 下層ページがなければバナーを非表示にする
  if (type === 'activities' && !vm.$hotel.hasActivity()) {
    return null;
  }
  if (type === 'todo' && !hotel.todo) {
    return null;
  }
  if (type === 'dining' && !vm.$hotel.hasDining()) {
    return null;
  }

  const hotelLocation = vm.$hotel.location({ path: type }, hotel.slug);

  // linkはHyperlinkなのでHyperlink型にする
  const hotelLink: HotelBannerLink['link'] = {
    key: '',
    text: '',
    url: hotelLocation.path!,
    blank: false,
  };

  // タイトルを自動生成（ナビから取り出す）する。 なかった場合は登録されているタイトルを使う。
  const section = vm.$theme.current.sections.find((section) => {
    return section.key === type;
  });
  const normalizedTitle = (section && section.label) || title;

  return { key, title: normalizedTitle, image, link: hotelLink, pageIds };
}

/**
 * 正規化済みの施設下層導線設定
 *
 * 全てのバナー（HotelBannerLinkをBannerLinkに正規化した状態にする）
 *
 * * このバナーの数が1件以上あるかどうかで、旧道線と、新動線（MyHotelFlowLine）を出し分ける
 */
export interface NormalizedHotelFlowLineSettings
  extends Omit<HotelFlowLineSettings, 'banners'> {
  banners: BannerLink[];
}

/**
 * 施設下層導線設定を正規化する
 *
 * @param settings - 施設下層導線設定
 * @returns 正規化済みの施設下層導線設定
 */
export function normalizeHotelFlowLineSettings(
  flowLineSettings: HotelFlowLineSettings,
  vm: Vue,
): NormalizedHotelFlowLineSettings {
  const normalizeBanners = flowLineSettings.banners
    .map((banner) => normalizeHotelBannerLink(banner, vm))
    .filter<BannerLink>((banner): banner is BannerLink => banner !== null);

  return {
    banners: normalizeBanners,
  };
}
