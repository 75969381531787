import './HBrandNavigation.scss';

import Vue from 'vue';
import * as tsx from 'vue-tsx-support';
import { Component, Prop } from 'vue-property-decorator';
import { HIcon } from '../HIcon';
import { HExpandTransition } from '../transitions';
import { createBrandLinks } from './helpers';
import { ComputedBrand, BrandNameDisplaySettingsPayload } from './schemes';
import {
  HBrandNavigationList,
  HBrandNavigationListProps,
} from './HBrandNavigationList';
import { HBrandNavigationHeader } from './HBrandNavigationHeader';
import { ThemeValue } from '~/server-middleware/data-server/adapter/ACCOAdapter/@types/';
import { HotelBrandBasicInfo } from '~/schemes';

export interface HBrandNavigationProps {
  /**
   * ブランド基本情報か、それにリンクを追加したオブジェクト
   *
   * * リンクを渡さなかった場合はこのコンポーネントの中で取得する
   */
  brand: HotelBrandBasicInfo | ComputedBrand;
}

export interface HBrandNavigationEmits {}

export interface HBrandNavigationScopedSlots {}

@Component<HBrandNavigationRef>({
  name: 'HBrandNavigation',
  render() {
    const { header } = this;
    const { logo } = header;

    // はじめから表示されている分
    const visibleLinks = this.computedLinks.slice(0, 8);

    // 隠れている分
    const hiddenLinks = this.computedLinks.slice(8);

    return (
      <div staticClass="h-brand-navigation">
        {/* PC */}
        <div staticClass="h-brand-navigation__pc">
          <HBrandNavigationHeader
            staticClass="h-brand-navigation__pc__header"
            name={this.brandName}
            category={this.displayBrandCategory}
          />
          <HBrandNavigationList
            visibleLinks={visibleLinks}
            hiddenLinks={hiddenLinks}
            logo={logo || undefined}
            brandPageLink={this.brandPageLinkSettings}
          />
        </div>

        {/* SP */}
        <div staticClass="h-brand-navigation__sp">
          <div
            staticClass="h-brand-navigation__sp__wrapper"
            onClick={this.toggle}>
            <HBrandNavigationHeader
              name={this.brandName}
              category={this.displayBrandCategory}
            />
            <HIcon
              name={this.isActive ? 'minus' : 'plus'}
              staticClass={`h-brand-navigation__sp__expandIcon ${
                this.isActive
                  ? 'h-brand-navigation__sp__expandIcon--active'
                  : ''
              }`}
            />
          </div>
          <HExpandTransition>
            <div v-show={this.isActive}>
              <HBrandNavigationList
                visibleLinks={this.computedLinks}
                logo={logo || undefined}
                brandPageLink={this.brandPageLinkSettings}
              />
            </div>
          </HExpandTransition>
        </div>
      </div>
    );
  },
})
export class HBrandNavigationRef extends Vue implements HBrandNavigationProps {
  /**
   * ブランド基本情報か、それにリンクを追加したオブジェクト
   *
   * * リンクを渡さなかった場合はこのコンポーネントの中で取得する
   */
  @Prop({ type: Object, required: true }) readonly brand!:
    | HotelBrandBasicInfo
    | ComputedBrand;

  /** アコーディオンの開閉状態 */
  private isActive: boolean = false;

  /** ロゴの上に表示するブランドカテゴリーは変更が頻繁にあるものではないのでCMSはなくここで管理 */
  private slugMap: Partial<Record<ThemeValue, string>> = {
    hoshinoya: this.$tc('label.brands.categories.hoshinoya'),
    kai: this.$tc('label.brands.categories.kai'),
    risonare: this.$tc('label.brands.categories.risonare'),
    omo: this.$tc('label.brands.categories.omo'),
    beb: this.$tc('label.brands.categories.beb'),
  };

  /** ロゴの上に表示するブランドのカテゴリー */
  get displayBrandCategory() {
    return this.slugMap[this.brand.slug] || '';
  }

  /** ブランドスラッグ */
  get slug() {
    return this.brand.slug;
  }

  /** ブランド名 */
  get brandName() {
    return this.brand.name;
  }

  /** ブランド名表示設定の各メソッドに渡す引数オブジェクト */
  get displaySettingsPayload(): BrandNameDisplaySettingsPayload {
    return {
      lang: this.$language.current,
      res: this.$res,
      slug: this.slug,
      brandName: this.brandName,
    };
  }

  /** ヘッダー表示設定 */
  get header() {
    const logo =
      this.brand.logo && this.brand.logo.noText && this.brand.logo.noText.light;

    return {
      /** ロゴの表示設定 */
      logo: logo && {
        src: logo,
        alt: '', // ロゴ横にはブランド名が表示されるのでaltは不要
      },
    };
  }

  /** このブランドに紐づくリンクのリスト */
  get computedLinks() {
    return (
      (this.brand as ComputedBrand).links || createBrandLinks(this, this.brand)
    ).map((link) => {
      // @TODO このmap処理は、以下の問題に対する残体対応です。
      // ページバックした場合、この対応だけでは問題解決にならずですが、緩和のために一時的にやってます
      // 根本原因を修正したら、この処理は削除してください
      if (!link.props) return link;
      if (link.attrs && link.attrs.href) return link;
      const { props, attrs } = link;
      const propsHref = props._href;
      if (!propsHref) return link;
      const _link = {
        ...link,
        TagName: 'a',
      };

      delete _link.props;
      _link.attrs = {
        ...attrs,
        href: propsHref,
      };
      return _link;
    });
  }

  /** ブランドページリンクの設定 */
  get brandPageLinkSettings(): HBrandNavigationListProps['brandPageLink'] {
    const { slug, url, urlText } = this.brand;

    if (!urlText) {
      return;
    }

    const brandUrl = url || `/${this.$language.current}/brands/${slug}/`;

    return {
      url: brandUrl,
      text: urlText,
    };
  }

  /** アコーディオンの開閉状態をトグルする */
  toggle() {
    this.isActive = !this.isActive;
  }
}

export const HBrandNavigation = tsx
  .ofType<
    HBrandNavigationProps,
    HBrandNavigationEmits,
    HBrandNavigationScopedSlots
  >()
  .convert(HBrandNavigationRef);
