import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import './HBrandNavigationHeader.scss';

export interface HBrandNavigationHeaderProps {
  name: string;
  category?: string;
}

export interface HBrandNavigationHeaderEmits {}

export interface HBrandNavigationHeaderScopedSlots {}

@Component<HBrandNavigationHeaderRef>({
  name: 'HBrandNavigationHeader',
  render() {
    return (
      <div staticClass="h-brand-navigation-header">
        <span staticClass="h-brand-navigation-header__name">{this.name}</span>
        {this.category && (
          <span staticClass="h-brand-navigation-header__separator">|</span>
        )}
        {this.category && (
          <span staticClass="h-brand-navigation-header__category">
            {this.category}
          </span>
        )}
      </div>
    );
  },
})
export class HBrandNavigationHeaderRef
  extends Vue
  implements HBrandNavigationHeaderProps {
  @Prop({ type: String, required: true })
  readonly name!: HBrandNavigationHeaderProps['name'];

  @Prop({ type: String })
  readonly category?: HBrandNavigationHeaderProps['category'];
}

export const HBrandNavigationHeader = tsx
  .ofType<
    HBrandNavigationHeaderProps,
    HBrandNavigationHeaderEmits,
    HBrandNavigationHeaderScopedSlots
  >()
  .convert(HBrandNavigationHeaderRef);
