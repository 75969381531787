import './HNotification.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Model, Prop, Watch } from 'vue-property-decorator';
import { bodyScrollLock } from '@dadajam4/vue-stack';
import { HExpandTransition, HIcon, HIconLink } from '~/components';
import { Notification, Hyperlink } from '~/schemes';

export interface HNotificationProps {
  value?: boolean;
  removable?: boolean;
  dense?: boolean;
  data: Notification | Notification[];
  overlayMode?: boolean;
  suggestedTopicLinks?: Hyperlink[];
}

export interface HNotificationEmits {
  onInput: boolean;
  onClickRemove: MouseEvent;
}

export interface HNotificationScopedSlots {}

@Component<HNotificationRef>({
  name: 'HNotification',
  directives: {
    bodyScrollLock,
  },

  render(h) {
    const { normalizedData, suggestedTopicLinks } = this;
    const headerChildren: VNode[] = [
      <HIcon
        staticClass="h-notification__header__icon"
        name="exclamation-outline"
      />,
      <div staticClass="h-notification__header__title">
        {normalizedData.title}
      </div>,
      <div staticClass="h-notification__header__toggle">
        <HIcon
          staticClass="h-notification__header__toggle__icon"
          name="keyboard-arrow-down"
        />
      </div>,
    ];

    // お知らせを非表示にするバツボタンの制御
    if (this.removable && !this.isActive) {
      headerChildren.push(
        <button
          staticClass="h-notification__remove"
          type="button"
          onClick={(ev) => {
            if (this.isActive) {
              this.isActive = false;
              ev.stopPropagation();
            } else {
              this.$emit('clickRemove', ev);
            }
          }}>
          <HIcon staticClass="h-notification__remove__icon" name="close" />
        </button>,
      );
    }

    const { body, href } = normalizedData;
    const innerChildren: VNode[] = [
      h(
        href ? 'a' : 'dt',
        {
          staticClass: 'h-notification__header',
          attrs: href
            ? {
                href,
                target: '_blank',
                rel: 'noopener',
              }
            : undefined,
          on: href
            ? undefined
            : {
                click: (e: MouseEvent) => {
                  this.toggle();
                },
              },
        },
        headerChildren,
      ),
    ];
    if (!href) {
      const children = Array.isArray(body)
        ? [
            h(
              'div',
              {
                staticClass: 'h-notification__rows',
              },
              [
                body.map((row, index) => {
                  const { title, body, link, createdAt } = row;
                  const url = link && link.url;
                  const target = link && link.blank ? '_blank' : undefined;
                  const titleTag = url ? 'a' : 'span';
                  const titleAttrs = url
                    ? {
                        href: url,
                        target,
                        rel: 'noopener',
                      }
                    : undefined;
                  const rowChildren: VNode[] = [
                    <h4 staticClass="h-notification__row__title">
                      {h(
                        titleTag,
                        {
                          staticClass: 'h-notification__row__title__inner',
                          attrs: titleAttrs,
                        },
                        title,
                      )}
                    </h4>,
                  ];
                  if (rowChildren) {
                    rowChildren.push(
                      <div
                        staticClass="h-notification__row__body"
                        v-wysiwyg={body}
                      />,
                    );
                  }

                  return (
                    <div
                      staticClass="h-notification__row"
                      key={`${createdAt || ''}-${index}`}>
                      {rowChildren}
                    </div>
                  );
                }),
                // 施設お知らせに表示する特集ページ
                suggestedTopicLinks && suggestedTopicLinks.length !== 0 && (
                  <div staticClass="h-notification__row">
                    <div staticClass="h-notification__row__special-pages">
                      <div staticClass="h-notification__row__special-pages__label">
                        {this.$t('label.specialPage')}
                      </div>
                      {suggestedTopicLinks.map((item) => {
                        const { text, url, blank } = item;
                        const target = blank ? '_blank' : undefined;
                        return (
                          <div staticClass="h-notification__row__special-page">
                            <HIconLink
                              staticClass="h-notification__row__special-page__title"
                              icon="arrow-right">
                              {h(
                                'a',
                                {
                                  staticClass:
                                    'h-notification__row__special-page__title__inner',
                                  attrs: {
                                    href: url,
                                    target,
                                    rel: 'noopener',
                                  },
                                },
                                text,
                              )}
                            </HIconLink>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ),
              ],
            ),
          ]
        : this.$slots.default;

      if (this.$mq.match.narrow) {
        children &&
          children.push(
            <div
              class="h-notification__row--close-button"
              onClick={(ev) => {
                ev.stopPropagation();
                this.isActive = false;
              }}>
              <div staticClass="h-notification__row--close-button__title">
                {this.$t('action.closeNotice')}
              </div>
              <div staticClass="h-notification__row--close-button__toggle">
                <HIcon
                  staticClass="h-notification__row--close-button__toggle__icon"
                  name="keyboard-arrow-up"
                />
              </div>
            </div>,
          );
      }

      innerChildren.push(
        <HExpandTransition>
          <dd
            staticClass="h-notification__body"
            v-show={this.isActive}
            ref="scroller">
            {h(
              'div',
              {
                staticClass: 'h-notification__body__inner',
                directives:
                  typeof body === 'string'
                    ? [
                        {
                          name: 'wysiwyg',
                          value: body,
                        },
                      ]
                    : [],
              },
              children,
            )}
            {this.overlayMode && (
              <transition name="fade">
                <div
                  v-show={this.isActive}
                  staticClass="h-notification__body__overlay"
                  v-body-scroll-lock={this.isActive}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    this.isActive = false;
                  }}
                />
              </transition>
            )}
          </dd>
        </HExpandTransition>,
      );
    }

    return (
      <div
        staticClass={`h-notification ${
          this.overlayMode ? 'h-notification--overlay' : ''
        }`}
        class={this.classes}>
        <dl staticClass="h-notification__inner">{innerChildren}</dl>
      </div>
    );
  },
})
export class HNotificationRef extends Vue implements HNotificationProps {
  $refs!: {
    scroller: HTMLElement;
  };

  @Model('input', { type: Boolean }) value!: boolean;
  @Prop({ type: Boolean }) removable!: boolean;
  @Prop({ type: Boolean }) overlayMode!: boolean;
  @Prop({ type: Boolean }) dense!: boolean;
  @Prop({ type: [Object, Array], required: true }) readonly data!:
    | Notification
    | Notification[];

  @Prop({ type: Array })
  readonly suggestedTopicLinks?: Hyperlink[];

  private internalValue: boolean = false;

  get normalizedData() {
    const { data } = this;
    if (Array.isArray(data)) {
      return {
        title: this.$i18n.t('label.notice') as string,
        href: null,
        body: data,
      };
    } else {
      const { link } = data;
      const url = link && link.url;
      return {
        title: data.title,
        href: url,
        body: data.body,
      };
    }
  }

  get isActive() {
    return this.internalValue;
  }

  set isActive(isActive: boolean) {
    if (this.internalValue !== isActive) {
      this.internalValue = isActive;
      this.$emit('input', isActive);
    }
  }

  get classes() {
    return {
      'h-notification--removable': this.removable,
      'h-notification--active': this.isActive,
      'h-notification--dense': this.dense,
      'h-notification--has-link': !!this.normalizedData.href,
    };
  }

  toggle() {
    this.isActive = !this.isActive;
  }

  @Watch('value')
  protected valueChangeHandler() {
    this.internalValue = this.value;
  }

  @Watch('internalValue')
  protected internalValueChangeHandler() {
    if (this.internalValue) {
      /**
       * iPhone Safariでアコーディオン開いた時、
       * スクロール位置がおかしい時がある
       */
      try {
        setTimeout(() => {
          const { scroller } = this.$refs;
          if (scroller) {
            scroller.scrollTop = 0;
          }
        }, 100);
      } catch (err) {
        // eslint-disable-next-line no-console
        this.$logger.warn(err);
      }
    }
  }
}

export const HNotification = tsx
  .ofType<HNotificationProps, HNotificationEmits, HNotificationScopedSlots>()
  .convert(HNotificationRef);
