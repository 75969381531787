import { Middleware } from '@nuxt/types';

const KAI_KAWAJI_RE = /^\/[^/]+\/hotels\/kaikawaji(?:\/|$)/;

const kaikawajiRedirectMiddleware: Middleware = (ctx) => {
  const { redirect, route } = ctx;
  const { path } = route;
  const lang = route.params.lang;
  if (KAI_KAWAJI_RE.test(path)) {
    return redirect(301, `/${lang}/brands/kai`);
  }
};

export default kaikawajiRedirectMiddleware;
