import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MyHotelFeatureItem } from './MyHotelFeatureItem';
import { MyHotelFeatureWideItem } from './MyHotelFeatureWideItem';
import { MyHotelFeatureConceptMovie } from './MyHotelFeatureConceptMovie';
import { MyHotelFeatureMiniGallery } from './MyHotelFeatureMiniGallery';
import { HotelDetailInfo } from '~/schemes';

export interface MyHotelFeatureProps {
  hotel: HotelDetailInfo;
  needPauseCarousel?: boolean;
}

export interface MyHotelFeatureEmits {}

export interface MyHotelFeatureScopedSlots {}

@Component<MyHotelFeatureRef>({
  name: 'MyHotelFeature',
  render() {
    const {
      isWide,
      features,
      needPauseCarousel,
      total,
      featureMiniGallery,
      featureConceptMovie,
    } = this;

    const ItemComponent = isWide
      ? ((MyHotelFeatureWideItem as unknown) as typeof MyHotelFeatureItem)
      : MyHotelFeatureItem;

    return (
      <div staticClass="my-hotel-feature">
        {features.map((feature, index) => (
          <ItemComponent
            key={index}
            needPauseCarousel={needPauseCarousel}
            staticClass="my-hotel-feature__item"
            data={feature}
            total={total}
            index={index + 1}
          />
        ))}

        {/* ミニギャラリー */}
        {featureMiniGallery && (
          <MyHotelFeatureMiniGallery
            images={featureMiniGallery}
            staticClass="my-hotel-feature__mini-gallery"
          />
        )}

        {/* コンセプトムービー */}
        {featureConceptMovie && (
          <MyHotelFeatureConceptMovie
            conceptMovie={featureConceptMovie}
            staticClass="my-hotel-feature__concept-movie"
          />
        )}
      </div>
    );
  },
})
export default class MyHotelFeatureRef
  extends Vue
  implements MyHotelFeatureProps {
  @Prop({ type: Object, required: true }) readonly hotel!: HotelDetailInfo;
  @Prop({ type: Boolean }) readonly needPauseCarousel!: boolean;

  // ACCO CMSでいうところのitems
  get features() {
    return this.hotel.features;
  }

  get total() {
    return this.features.length;
  }

  get isWide() {
    return this.$theme.is('risonare');
  }

  /** ミニギャラリー */
  get featureMiniGallery() {
    return this.hotel.featureMiniGallery;
  }

  /** コンセプトムービー */
  get featureConceptMovie() {
    return this.hotel.featureConceptMovie;
  }
}

export const TypedMyHotelFeature = tsx
  .ofType<MyHotelFeatureProps, MyHotelFeatureEmits, MyHotelFeatureScopedSlots>()
  .convert(MyHotelFeatureRef);
