import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelFeature } from '~/schemes';

export interface MyHotelFeatureItemTitleProps {
  data: HotelFeature;
  total: number;
  index: number;
  needCount?: boolean;
}

@Component<MyHotelFeatureItemTitleRef>({
  name: 'MyHotelFeatureItemTitle',
  render() {
    const { index, total, data, needCount } = this;

    return (
      <h3 staticClass="my-hotel-feature-item__title" data-index={index}>
        {needCount && (
          <span staticClass="my-hotel-feature-item__title__count">
            <span staticClass="my-hotel-feature-item__title__count__index">
              {index}
            </span>
            <span staticClass="my-hotel-feature-item__title__count__divider" />
            <span staticClass="my-hotel-feature-item__title__count__total">
              {total}
            </span>
          </span>
        )}

        <span staticClass="my-hotel-feature-item__title__text">
          {data.title}
        </span>
      </h3>
    );
  },
})
export class MyHotelFeatureItemTitleRef
  extends Vue
  implements MyHotelFeatureItemTitleProps {
  @Prop({ type: Object, required: true }) readonly data!: HotelFeature;
  @Prop({ type: Number, required: true }) readonly total!: number;
  @Prop({ type: Number, required: true }) readonly index!: number;
  @Prop({ type: Boolean }) readonly needCount!: boolean;
}

export const MyHotelFeatureItemTitle = tsx
  .ofType<MyHotelFeatureItemTitleProps>()
  .convert(MyHotelFeatureItemTitleRef);
