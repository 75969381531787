import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MyHotelInstantPhoto } from '../MyHotelInstantPhoto';
import { MyHotelFeatureItemTitle } from './MyHotelFeatureItemTitle';
import { HotelFeature, MediaInfoMeta } from '~/schemes';
import {
  HSimpleCarousel,
  HSimpleCarouselItem,
  HBtn,
  HPeepMedia,
  HSimpleDotPagination,
  HImg,
} from '~/components';

export interface HotelFeatureItemImage {
  image: string;
  meta?: MediaInfoMeta;
}

export interface MyHotelFeatureItemProps {
  data: HotelFeature;
  total: number;
  index: number;
  needPauseCarousel?: boolean;
}

export interface MyHotelFeatureItemEmits {}

export interface MyHotelFeatureItemScopedSlots {}

@Component<MyHotelFeatureItemRef>({
  name: 'MyHotelFeatureItem',
  render() {
    const {
      isSingle,
      carouselItems,
      needPauseCarousel,
      index,
      total,
      data,
      images,
      narrowImages,
      link,
    } = this;

    return (
      <div staticClass="my-hotel-feature-item">
        {this.isHoshinoyaTheme && (
          <HSimpleDotPagination
            staticClass="my-hotel-feature-item__pagination"
            items={images}
            aspectRatio="2/1"
          />
        )}
        {this.isLucyTheme && (
          <MyHotelInstantPhoto
            staticClass="my-hotel-feature-item__instant-photo"
            src={images[0].image}
            axis="horizontal"
            stackTiltAngle={index % 2 === 0 ? -6 : 6}
          />
        )}
        {!isSingle &&
          !this.isHoshinoyaTheme &&
          !this.isKaiTheme &&
          !this.isLucyTheme && (
            <HSimpleCarousel
              staticClass="my-hotel-feature-item__carousel"
              items={carouselItems}
              paused={needPauseCarousel}
            />
          )}
        {/* 画像が1枚のみ登録されている場合、画像の表示は静止した状態にする */}
        {isSingle &&
          !this.isKaiTheme &&
          !this.isHoshinoyaTheme &&
          !this.isLucyTheme && (
            <HPeepMedia
              staticClass="my-hotel-feature-item__single-img"
              src={images[0]}
              reverse={index % 2 === 0}
            />
          )}

        <div staticClass="my-hotel-feature-item__body">
          <MyHotelFeatureItemTitle
            index={index}
            total={total}
            data={data}
            needCount={!this.isHoshinoyaTheme && !this.isLucyTheme}
          />

          <div staticClass="my-hotel-feature-item__body__inner">
            {/* 界の表示 */}
            {this.isKaiTheme &&
              (narrowImages[0] && this.$mq.match.narrow ? (
                <HImg
                  src={narrowImages[0].image}
                  staticClass="my-hotel-feature-item__single-img my-hotel-feature-item__single-img--still"
                  inview
                />
              ) : (
                <HPeepMedia
                  staticClass="my-hotel-feature-item__single-img"
                  src={images[0]}
                  reverse={index % 2 === 0}
                />
              ))}

            <div
              v-wysiwyg={data.description}
              staticClass="my-hotel-feature-item__description"
            />
            {/* 3つのおすすめのリンクボタン */}
            {!!link && (
              <div staticClass="my-hotel-feature-item__link">
                <HBtn
                  props={{
                    ...link.props,
                    color: this.isLucyTheme ? 'skelton' : 'plain',
                    block: true,
                    depressed: this.isLucyTheme,
                  }}>
                  {link.text}
                </HBtn>
              </div>
            )}
          </div>
        </div>
        {/* イラスト (現状はLUCYのみ対応) */}
        {this.data.illust && (
          <img
            staticClass="my-hotel-feature-item__illust"
            src={this.data.illust}
            alt="イラスト"
          />
        )}
      </div>
    );
  },
})
export class MyHotelFeatureItemRef
  extends Vue
  implements MyHotelFeatureItemProps {
  @Prop({ type: Object, required: true }) readonly data!: HotelFeature;
  @Prop({ type: Number, required: true }) readonly total!: number;
  @Prop({ type: Number, required: true }) readonly index!: number;
  @Prop({ type: Boolean }) readonly needPauseCarousel!: boolean;

  get isKaiTheme() {
    return this.$theme.current.name === 'kai';
  }

  get isHoshinoyaTheme() {
    return this.$theme.current.name === 'hoshinoya';
  }

  get isLucyTheme() {
    return this.$theme.current.name === 'lucy';
  }

  get images(): HotelFeatureItemImage[] {
    return (this.data.images || []).map(({ main, meta }) => {
      return {
        image: this.$res.img(main),
        meta,
      };
    });
  }

  get narrowImages(): HotelFeatureItemImage[] {
    return (this.data.narrowImages || []).map(({ main, meta }) => {
      return {
        image: this.$res.img(main),
        meta,
      };
    });
  }

  get isSingle() {
    return this.images.length === 1;
  }

  get carouselItems(): HSimpleCarouselItem[] {
    return this.images.map((image) => {
      return {
        images: [image],
      };
    });
  }

  get link() {
    const { link } = this.data;
    if (!link) return;
    const { url, text } = link;
    if (!text || !url) return;
    return {
      url,
      text,
      props: this.$navigation.resolveHrefTo(url),
    };
  }
}

export const MyHotelFeatureItem = tsx
  .ofType<
    MyHotelFeatureItemProps,
    MyHotelFeatureItemEmits,
    MyHotelFeatureItemScopedSlots
  >()
  .convert(MyHotelFeatureItemRef);
