// 地図用座標
export interface Geolocation {
  /** 緯度 */
  lat: number;
  /** 軽度 */
  lng: number;
}

/** ギャラリーリスト */
export interface CrossSearchGalleryDto {
  /** 画像URL */
  imageUrl: CrossSearchImageUrl;
  /** カテゴリ */
  category: string;
  /** カテゴリインデックス */
  categoryIndex: number;
  /** 表示順 */
  displayOrder: number;
  /** ギャラリー表示フラグ */
  galleryDisplayFlg: boolean;
}

/** 画像URL */
export interface CrossSearchImageUrl {
  /** 小サムネイルURL */
  sSizeUrl: string;
  /** 中サムネイルURL */
  mSizeUrl: string;
  /** 元サイズ画像URL */
  rawSizeUrl: string;
}

/** 横断検索マスタインターフェース */
export interface CrossSearchHotelMaster {
  /** 施設マスタ */
  hotelMasterList: HotelMasterList[];
}

/** 横断検索の施設マスタのインターフェース */
export interface HotelMasterList {
  /** 施設ID */
  hotelId: string;
  /** ブランド種別 */
  brandType: BrandType;
  /** 基準通貨 */
  quote: string;
  /** エリアID */
  areaId: number;
  /** 施設名 */
  name: string;
  /** 場所名 */
  placeName: string;
  /** 説明 */
  description: string;
  /** タグリスト */
  tagList: string[];
  /** 施設特徴情報リスト */
  characteristicIdList: number[];
  /** 主要施設情報リスト */
  mainFacilityIdList: number[];
  /** 地図用座標 */
  geolocation: Geolocation;
  /** 詳細説明文 */
  detail: string;
  /** 施設住所 */
  address: string;
  /** 電話番号 */
  phoneNumber: number;
  /** ギャラリーリスト */
  crossSearchGalleryList: CrossSearchGalleryDto[];
  /** 埋め込み地図情報 */
  embeddedMapInfo: string;
  /** 一覧画面制限文言 */
  restrictionText: string;
  /** 詳細画面制限文言 */
  detailRestrictionText: string;
  /** ブランドコード */
  brandCode: number;
}

// 横断検索のデータの箱
export interface CrossSearchDetail {
  /** 横断検索のマスタ */
  crossSearch: CrossSearchHotelMaster | null;
  /** 宿GETSの施設詳細情報の有無の判定が完了しているか */
  _crossSearchDetected: boolean;
}

/** ブランド種別のenum */
export enum BrandType {
  hoshinoya = 0,
  risonare = 1,
  kai = 2,
  uniquehotels = 3,
  omo = 4,
  beb = 5,
  lucy = 6,
}
