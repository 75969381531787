import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MyHotelDetailItem } from './MyHotelDetailItem';
import { HotelDetail, RestaurantCategory } from '~/schemes';
import {
  extractHotelFacilities,
  hour12,
  dumpGeneralOptions2Text,
} from '~/helpers';
import { HIcon, HPathIcon } from '~/components';

export interface MyHotelDetailProps {
  hotel: HotelDetail;
}

export interface MyHotelDetailEmits {}

export interface MyHotelDetailScopedSlots {}

@Component<MyHotelDetailRef>({
  name: 'MyHotelDetail',
  render() {
    const {
      buildingInfo,
      hotel,
      guestsRange,
      checkInOut,
      displayCheckInOut,
      pets,
      facilities,
      creditCards,
      payments,
    } = this;
    const { insideMaps } = hotel;

    return (
      <div staticClass="my-hotel-detail">
        <div staticClass="my-hotel-detail__rows">
          {/* 総客室数 */}
          {!!buildingInfo && (
            <MyHotelDetailItem
              id="hoteldetail-guest-rooms"
              staticClass="my-hotel-detail__row"
              icon="hotel-outline"
              label={this.$t('label.guestRooms')}
              scopedSlots={{
                value: () => buildingInfo,
              }}
            />
          )}

          {!!insideMaps && insideMaps.length > 0 && (
            <MyHotelDetailItem
              id="hoteldetail-maps"
              staticClass="my-hotel-detail__row"
              icon="map"
              label={this.$t('label.enMaps')}
              scopedSlots={{
                value: () => (
                  <ul staticClass="my-maps-list">
                    {insideMaps.map((map, mapIndex) => (
                      <li key={mapIndex} staticClass="my-maps-list__item">
                        <a
                          staticClass="my-maps-list__item__link"
                          href={this.$res.img(map.url)}
                          target="_blank"
                          rel="noopener">
                          <HIcon
                            staticClass="my-maps-list__item__icon"
                            name="arrow-right"
                          />
                          <span staticClass="my-maps-list__item__label">
                            {map.text}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                ),
              }}
            />
          )}

          {/* 客室定員 */}
          {!!guestsRange && (
            <MyHotelDetailItem
              id="hoteldetail-peoples"
              staticClass="my-hotel-detail__row"
              icon="peoples"
              label={this.$t('label.roomOccupancy')}
              scopedSlots={{
                value: () => guestsRange,
              }}
            />
          )}

          {/* レストラン */}
          {this.restaurants.length > 0 && (
            <MyHotelDetailItem
              id="hoteldetail-restaurants"
              staticClass="my-hotel-detail__row"
              icon="restaurant-outline"
              label={this.$t('label.restaurant')}
              restaurants={this.restaurants}
              eventId="restaurant"
            />
          )}

          {/* カフェ/ラウンジ */}
          {this.cafeAndLounges.length > 0 && (
            <MyHotelDetailItem
              id="hoteldetail-cafe-and-lounge"
              staticClass="my-hotel-detail__row"
              icon="local-cafe-outline"
              label={this.$t('label.cafeAndLounge')}
              restaurants={this.cafeAndLounges}
              eventId="cafe-and-lounge"
            />
          )}

          {/* 温泉 */}
          {hotel.spa.length > 0 && (
            <MyHotelDetailItem
              id="hoteldetail-spa"
              staticClass="my-hotel-detail__row"
              icon="massage"
              label={this.$t('label.spaAndMassage')}
              options={hotel.spa}
              eventId="spa"
            />
          )}

          {/* キッズルーム */}
          {hotel.kidsRoom.length > 0 && (
            <MyHotelDetailItem
              id="hoteldetail-playroom"
              staticClass="my-hotel-detail__row"
              icon="smlie-outline"
              label={this.$t('label.playroom')}
              options={hotel.kidsRoom}
              eventId="kids-room"
            />
          )}

          {/* プール */}
          {hotel.pool.length > 0 && (
            <MyHotelDetailItem
              id="hoteldetail-pool"
              staticClass="my-hotel-detail__row"
              icon="swimming"
              label={this.$t('label.pool')}
              options={hotel.pool}
              eventId="pool"
            />
          )}

          {/* 温泉 */}
          {hotel.onsen.length > 0 && (
            <MyHotelDetailItem
              id="hoteldetail-onsen"
              staticClass="my-hotel-detail__row"
              icon="onsen"
              label={this.$t('label.onsen')}
              options={hotel.onsen}
              eventId="onsen"
            />
          )}

          {/* 温浴施設 */}
          {hotel.publicBath.length > 0 && (
            <MyHotelDetailItem
              id="hoteldetail-publicBath"
              staticClass="my-hotel-detail__row"
              icon="onsen"
              label={this.$t('label.publicBath')}
              options={hotel.publicBath}
              eventId="publicBath"
            />
          )}

          {/* スキー */}
          {hotel.skiing.length > 0 && (
            <MyHotelDetailItem
              id="hoteldetail-skiing"
              staticClass="my-hotel-detail__row"
              icon="skiing"
              label={this.$t('label.skiing')}
              options={hotel.skiing}
              eventId="skiing"
            />
          )}

          {/* 会議室 */}
          {!!this.conferenceRoom && (
            <MyHotelDetailItem
              id="hoteldetail-meeting-room"
              staticClass="my-hotel-detail__row"
              icon="meeting-room"
              label={this.$t('label.meetingRoom')}
              scopedSlots={{
                value: () => {
                  const children: VNode[] = [];
                  const { conferenceRoom } = this;
                  if (!conferenceRoom) return children;

                  if (!conferenceRoom.url) {
                    children.push(
                      <div staticClass="my-hotel-detail__row__columns">
                        <div
                          v-wysiwyg={conferenceRoom.text}
                          staticClass="my-hotel-detail__row__column"
                        />
                        {!!conferenceRoom.note && (
                          <div
                            v-wysiwyg={conferenceRoom.note}
                            staticClass="my-hotel-detail__row__column"
                          />
                        )}
                      </div>,
                    );
                  } else {
                    children.push(
                      <a
                        staticClass="my-hotel-detail__row__columns"
                        href={conferenceRoom.url}
                        target="_blank"
                        rel="noopener">
                        <div
                          v-wysiwyg={conferenceRoom.text}
                          staticClass="my-hotel-detail__row__column"
                        />
                        {!!conferenceRoom.note && (
                          <div
                            v-wysiwyg={conferenceRoom.note}
                            staticClass="my-hotel-detail__row__column"
                          />
                        )}
                      </a>,
                    );
                  }

                  return children;
                },
              }}
            />
          )}

          {/* チェックイン/チェックアウト */}
          {checkInOut && (
            <MyHotelDetailItem
              id="hoteldetail-check-in-out"
              staticClass="my-hotel-detail__row"
              icon="clock-outline"
              label={this.$t('label.checkInOut')}
              scopedSlots={{
                value: () => displayCheckInOut,
              }}
            />
          )}

          {/* wifi */}
          <MyHotelDetailItem
            id="hoteldetail-wifi"
            staticClass="my-hotel-detail__row"
            icon="wifi"
            label={this.$t('label.wifi')}
            scopedSlots={{
              value: () => hotel.wifi || (this.$t('guide.freeWifi') as string),
            }}
          />

          {/* 子供添い寝 */}
          <MyHotelDetailItem
            id="hoteldetail-baby"
            staticClass="my-hotel-detail__row"
            icon="baby"
            label={this.$t('label.childrenAndBeds')}
            scopedSlots={{
              value: () => hotel.childLying || '-',
            }}
          />

          {/* ペット */}
          <MyHotelDetailItem
            id="hoteldetail-pets"
            staticClass="my-hotel-detail__row"
            icon="pet-outline"
            label={this.$t('label.petsAccepted')}
            scopedSlots={{
              value: () => (
                <div staticClass="my-hotel-detail__row__columns">
                  <div
                    v-wysiwyg={pets.text}
                    staticClass="my-hotel-detail__row__column"
                  />
                  {!!pets.note && (
                    <div
                      v-wysiwyg={pets.note}
                      staticClass="my-hotel-detail__row__column"
                    />
                  )}
                </div>
              ),
            }}
          />

          {/* 記念日・お祝い */}
          {!!hotel.celebration && (
            <MyHotelDetailItem
              id="hoteldetail-special-arrangements"
              staticClass="my-hotel-detail__row"
              icon="gift-outline"
              label={this.$t('label.specialArrangements')}
              scopedSlots={{
                value: () => (
                  <div
                    v-wysiwyg={
                      hotel.celebration || this.$t('chore.notAvailable')
                    }
                  />
                ),
              }}
            />
          )}

          {/* ウェディング */}
          {!!hotel.wedding && (
            <MyHotelDetailItem
              id="hoteldetail-wedding"
              staticClass="my-hotel-detail__row"
              icon="jewelry-outline"
              label={this.$t('label.wedding')}
              scopedSlots={{
                value: () => (
                  <div
                    v-wysiwyg={hotel.wedding}
                    staticClass="my-wedding__memo"></div>
                ),
              }}
            />
          )}

          {/* 送迎 */}
          <MyHotelDetailItem
            id="hoteldetail-transfer"
            staticClass="my-hotel-detail__row"
            icon="bus-outline"
            label={this.$t('label.shuttleService')}
            scopedSlots={{
              value: () => (
                <div
                  v-wysiwyg={hotel.transfer || this.$t('chore.notAvailable')}
                />
              ),
            }}
          />

          {/* 駐車場 */}
          <MyHotelDetailItem
            id="hoteldetail-parking"
            staticClass="my-hotel-detail__row"
            icon="parking-outline"
            label={this.$t('label.parking')}
            scopedSlots={{
              value: () => (
                <div
                  v-wysiwyg={hotel.parking || this.$t('chore.notAvailable')}
                />
              ),
            }}
          />

          {/* EV充電器 */}
          <MyHotelDetailItem
            id="hoteldetail-ev-charger"
            staticClass="my-hotel-detail__row"
            icon="ev-charger-outline"
            label={this.$t('label.evCharger')}
            scopedSlots={{
              value: () => (
                <div
                  v-wysiwyg={hotel.evCharger || this.$t('chore.notAvailable')}
                />
              ),
            }}
          />

          {/* 事前払い */}
          <MyHotelDetailItem
            id="hoteldetail-official-site-payments"
            staticClass="my-hotel-detail__row"
            icon="credit-card-blank-outline"
            label={this.$t('label.onlinePayments')}
            scopedSlots={{
              value: () => {
                const children: (VNode | string)[] = [creditCards];
                if (this.usePaidy(children)) {
                  children.push(
                    <div staticClass="my-hotel-detail__paidy">
                      <HIcon
                        staticClass="my-hotel-detail__paidy--arrow-right"
                        name="arrow-right"
                      />
                      <a
                        class="my-hotel-detail__paidy--link"
                        href="https://hoshinoresorts.com/ja/sp/paidy/"
                        target="_blank"
                        rel="noopener noreferrer">
                        {this.$t('paidy.whatIsPaidy')}
                      </a>
                    </div>,
                  );
                }
                return children;
              },
            }}
          />

          {/* 現地払い */}
          {!!hotel.payment[0] && (
            <MyHotelDetailItem
              id="hoteldetail-hall-payments"
              staticClass="my-hotel-detail__row"
              label={this.$t('label.onsitePayments')}
              icon="credit-card-blank-outline"
              scopedSlots={{
                value: () => {
                  const children: (VNode | string)[] = [payments];
                  if (this.cashNotPossible(children)) {
                    children.push(
                      <div staticClass="my-hotel-detail__cash-not-possible">
                        {this.$i18n.t('label.cashNotPossibleCaution')}
                      </div>,
                    );
                  }
                  return children;
                },
              }}
            />
          )}

          {/* 消灯時間 */}
          {hotel.lightsOffTime && (
            <MyHotelDetailItem
              id="hoteldetail-light-off-time"
              staticClass="my-hotel-detail__row"
              icon="moon-outline"
              label={this.$t('label.lightOffTime')}
              scopedSlots={{
                value: () => (
                  <div
                    v-wysiwyg={
                      hotel.lightsOffTime || this.$t('chore.notAvailable')
                    }
                  />
                ),
              }}
            />
          )}

          {/* 荷物預かり */}
          {hotel.luggageStorage && (
            <MyHotelDetailItem
              id="hoteldetail-luggage-storage"
              staticClass="my-hotel-detail__row"
              icon="suitcase-outline"
              label={this.$t('label.luggageStorage')}
              scopedSlots={{
                value: () => (
                  <div
                    v-wysiwyg={
                      hotel.luggageStorage || this.$t('chore.notAvailable')
                    }
                  />
                ),
              }}
            />
          )}

          {/* 飲み水提供 */}
          {hotel.waterService && (
            <MyHotelDetailItem
              id="hoteldetail-water-service"
              staticClass="my-hotel-detail__row"
              icon="water-outline"
              label={this.$t('label.waterService')}
              scopedSlots={{
                value: () => (
                  <div
                    v-wysiwyg={
                      hotel.waterService || this.$t('chore.notAvailable')
                    }
                  />
                ),
              }}
            />
          )}

          {/* 営業期間 */}
          {hotel.businessPeriod && (
            <MyHotelDetailItem
              id="hoteldetail-business-period"
              staticClass="my-hotel-detail__row"
              icon="calendar-month-outline"
              label={this.$t('label.businessPeriod')}
              scopedSlots={{
                value: () => (
                  <div
                    v-wysiwyg={
                      hotel.businessPeriod || this.$t('chore.notAvailable')
                    }
                  />
                ),
              }}
            />
          )}

          {/* 主要施設 */}
          <MyHotelDetailItem
            id="hoteldetail-facilities"
            staticClass="my-hotel-detail__row"
            icon="info-circle-outline"
            label={this.$t('label.mainFacilities')}
            scopedSlots={{
              value: () => (
                <ul staticClass="my-facility-list">
                  {facilities.map((facility) => (
                    <li
                      key={facility.id}
                      staticClass="my-facility-list__item"
                      class={`my-facility-list__item--${
                        facility.active ? 'active' : 'disabled'
                      }`}>
                      <HPathIcon
                        name={
                          (facility.active ? 'circle-outline' : 'x-mark') as any
                        }
                        staticClass="my-facility-list__item__icon"
                      />
                      <span staticClass="my-facility-list__item__label">
                        {facility.name}
                      </span>
                    </li>
                  ))}
                </ul>
              ),
            }}
          />

          {/* その他 */}
          <MyHotelDetailItem
            id="hoteldetail-memo"
            staticClass="my-hotel-detail__row"
            icon="ellipsis-h-circle-outline"
            label={this.$t('label.generalMemo')}
            scopedSlots={{
              value: () => {
                if (!hotel.memo.length) return <span>-</span>;
                return (
                  <ul staticClass="my-hotel-detail__memos">
                    {this.hotel.memo.map((memo, index) => (
                      <li
                        key={index}
                        v-wysiwyg={memo}
                        staticClass={this.controlOtherItemClassName}
                      />
                    ))}
                  </ul>
                );
              },
            }}
          />
        </div>
      </div>
    );
  },
})
export default class MyHotelDetailRef
  extends Vue
  implements MyHotelDetailProps {
  @Prop({ type: Object, required: true }) hotel!: HotelDetail;

  /** 一行の時だけlistの表示をさせないように調整 */
  get controlOtherItemClassName(): string {
    return this.hotel.memo.length === 1
      ? 'my-hotel-detail__memo--single_list'
      : 'my-hotel-detail__memo';
  }

  get checkInOut() {
    const { checkIn, checkOut } = this.hotel;
    if (!checkIn || !checkOut) return false;
    return true;
  }

  /**
   * ホテル詳細表示用のチェックイン / アウト
   */
  get displayCheckInOut() {
    const { checkIn, checkOut, checkInLimit } = this.hotel;
    // フォーマット後のチェックイン時刻
    const formattedCheckIn = hour12(checkIn);
    // フォーマット後のチェックアウト時刻
    const formattedCheckOut = hour12(checkOut);
    // チェックイン最終時刻が存在しない場合
    if (!checkInLimit) {
      return `${formattedCheckIn} / ${formattedCheckOut}`;
    }
    // フォーマット後のチェックイン最終時刻
    const formattedCheckInLimit = hour12(checkInLimit);
    return `${formattedCheckIn}${this.$t(
      'chore.dash',
    )}${formattedCheckInLimit} / ${formattedCheckOut}`;
  }

  get restaurants() {
    return this.hotel.restaurants.filter(
      (r) => r.category === RestaurantCategory.Restaurant,
    );
  }

  get guestsRange(): string {
    const { roomCapacity } = this.hotel;
    if (!roomCapacity) return '';
    const { min, max } = roomCapacity;
    if (min == null && max == null) return '';
    const _min = min == null ? '' : min;
    const _max = max == null ? '' : max;
    return this.$t('value.guestsRange', {
      guestsRange: _min + (this.$t('chore.dash') as string) + _max,
    }) as string;
  }

  get conferenceRoom() {
    const { type, url, note } = this.hotel.conferenceRoom;
    let text: string;
    if (type === 'not-exist') {
      if (!url && !note) return;
      text = this.$t('chore.notExist') as string;
    } else if (type === 'exist') {
      text = this.$t('chore.exist') as string;
    } else if (type === 'contact') {
      text = this.$t('chore.contact') as string;
    } else {
      return;
    }

    return {
      // text: text + (note == null ? '' : `${this.$t('chore.space')}${note}`),
      text,
      note,
      url,
    };
  }

  get pets() {
    const { type, note } = this.hotel.pets;
    let text: string;
    if (type === 'allowed') {
      text = this.$t('chore.allowed') as string;
    } else if (type === 'no') {
      text = this.$t('chore.notAllowed') as string;
    } else {
      text = '';
    }
    return {
      text,
      note,
    };
  }

  get cafeAndLounges() {
    return this.hotel.restaurants.filter(
      (r) => r.category === RestaurantCategory.CafeAndLounge,
    );
  }

  get facilities() {
    return extractHotelFacilities(this.hotel, this);
  }

  get buildingInfo(): string {
    const { numOfRooms, numOfBuildings, area } = this.hotel;
    if (numOfRooms == null && numOfBuildings == null && area == null) {
      return '';
    }
    const tmp: string[] = [this.$tc('value.numOfRooms', numOfRooms)];
    if (numOfBuildings !== null) {
      tmp.push(this.$tc('value.numOfBuildings', numOfBuildings));
    }
    if (area !== null) {
      tmp.push(`${area}ha`);
    }
    return tmp.join(this.$t('chore.wordSparator') as string);
  }

  /**
   * クレジットカードと書いているが、CMSホテル詳細の事前払い情報取得
   * @memo よきタイミングで変数名を変えたい
   */
  get creditCards() {
    const { creditCard } = this.hotel;

    if (creditCard.length === 0) {
      return this.$t('chore.notAccepted') as string;
    }
    return dumpGeneralOptions2Text(this.$commons.creditCards, creditCard, this);
  }

  /** 現地払い情報取得 */
  get payments() {
    const { payment } = this.hotel;
    return dumpGeneralOptions2Text(this.$commons.payments, payment, this);
  }

  get reservationCenterNotice() {
    return this.$hotel.currentReservationCenterNotice;
  }

  /** ペイディを事前払いで使用できるかどうかの判定 */
  private usePaidy(creditCards: (VNode | string)[]): boolean {
    const word = this.$i18n.t('paidy.paidy') as string;
    // 日本以外にはPaidyは使えないはずなのでペイディのリンクは非表示
    if (word === '') {
      return false;
    }
    return (creditCards[0] as string).includes(word);
  }

  /**
   * 現金が使用できない時にtrue
   * i18nに登録された内容とマスタで登録されている内容が同じでないと表示されてしまうので、もっといい仕様にしたい
   */
  private cashNotPossible(payments: (VNode | string)[]): boolean {
    return !(payments[0] as string).includes(
      this.$i18n.t('label.cash') as string,
    );
  }
}

export const TypedMyHotelDetail = tsx
  .ofType<MyHotelDetailProps, MyHotelDetailEmits, MyHotelDetailScopedSlots>()
  .convert(MyHotelDetailRef);
