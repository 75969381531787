import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import './HBrandNavigationList.scss';
import { HBtn } from '../HBtn';
import { HBrandNavigationListItem } from './HBrandNavigationListItem';
import { ComputedBrand } from './schemes';

export interface HBrandNavigationListProps {
  /** はじめから表示させておくリンク */
  visibleLinks: ComputedBrand['links'];
  /** 隠しておくリンク */
  hiddenLinks?: ComputedBrand['links'];
  /** ブランドロゴ */
  logo?: {
    src: string;
    alt: string;
  };
  brandPageLink?: {
    /** ブランドページのURL */
    url: string;
    /** ブランドナビゲーションテキスト */
    text: string;
  };
}

export interface HBrandNavigationListEmits {}

export interface HBrandNavigationListScopedSlots {}

@Component<HBrandNavigationListRef>({
  name: 'HBrandNavigationList',
  render() {
    return (
      <div class="h-brand-navigation-list">
        {this.visibleLinks.map((link, key) => (
          <HBrandNavigationListItem key={key} item={link} />
        ))}

        {this.existsHiddenLinks && !this.isActive && (
          <HBtn
            staticClass="h-brand-navigation-list__more"
            toggleIcon
            depressed
            onClick={this.toggleIsActive}>
            {this.$i18n.t('label.footer.total', {
              count: this.allLinkCount,
            })}
          </HBtn>
        )}

        {this.hiddenLinks &&
          this.isActive &&
          this.hiddenLinks.map((link, key) => (
            <HBrandNavigationListItem key={key} item={link} />
          ))}

        {this.existsHiddenLinks && this.isActive && (
          <HBtn
            staticClass="h-brand-navigation-list__more h-brand-navigation-list__more--open"
            toggleIcon
            depressed
            onClick={this.toggleIsActive}>
            {this.$i18n.t('label.footer.showLess')}
          </HBtn>
        )}

        {this.logo && this.brandPageLink && (
          <HBtn
            staticClass="h-brand-navigation-list__brandLink"
            depressed
            href={this.brandPageLink.url}>
            <img
              staticClass="h-brand-navigation-list__brandLink__logo"
              src={this.logo.src}
              alt={this.logo.alt}
            />
            <span staticClass="h-brand-navigation-list__brandLink__text">
              {this.brandPageLink.text}
            </span>
          </HBtn>
        )}
      </div>
    );
  },
})
export class HBrandNavigationListRef
  extends Vue
  implements HBrandNavigationListProps {
  @Prop({ type: Array, required: true })
  readonly visibleLinks!: HBrandNavigationListProps['visibleLinks'];

  @Prop({ type: Array })
  readonly hiddenLinks?: HBrandNavigationListProps['hiddenLinks'];

  @Prop({ type: Object })
  readonly logo?: HBrandNavigationListProps['logo'];

  @Prop({ type: Object })
  readonly brandPageLink?: HBrandNavigationListProps['brandPageLink'];

  /** もっと見るの開閉状態 */
  private isActive: boolean = false;

  /** 隠しておくリンクがあるかどうか */
  get existsHiddenLinks(): boolean {
    return !!this.hiddenLinks && this.hiddenLinks.length > 0;
  }

  /** 全リンク数 */
  get allLinkCount(): number {
    const hiddenLinks = this.hiddenLinks || [];
    return this.visibleLinks.length + (hiddenLinks.length || 0);
  }

  /** もっと見るの開閉状態をトグルする */
  toggleIsActive() {
    this.isActive = !this.isActive;
  }
}

export const HBrandNavigationList = tsx
  .ofType<
    HBrandNavigationListProps,
    HBrandNavigationListEmits,
    HBrandNavigationListScopedSlots
  >()
  .convert(HBrandNavigationListRef);
