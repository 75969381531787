import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods_19ld5ay } from './brands/overviews/by-slug/_brandSlug@string';
import type { Methods as Methods_1q2mzsf } from './brands/summaries';
import type { Methods as Methods_y6905x } from './facilities/overviews/by-code/_facilityCode@string';
import type { Methods as Methods_v2ojx4 } from './facilities/overviews/by-slug/_hotelSlug@string';
import type { Methods as Methods_10pranw } from './facilities/summaries';
import type { Methods as Methods_14xwhh2 } from './member/login';
import type { Methods as Methods_yugit3 } from './member/logout';
import type { Methods as Methods_1cs8q36 } from './member/profile';
import type { Methods as Methods_4ofto2 } from './search';
import type { Methods as Methods_le7gmx } from './settings/basics/gallery-categories';
import type { Methods as Methods_pl9tuj } from './settings/currencies';
import type { Methods as Methods_1os4x0t } from './settings/global-site-settings';
import type { Methods as Methods_rdms38 } from './special-pages';
import type { Methods as Methods_1a35v2c } from './special-pages/get-by-space';
import type { Methods as Methods_2ectt2 } from './special-pages/search-by-space';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:4300/.gf-api' : baseURL).replace(/\/$/, '');
  const PATH0 = '/brands/overviews/by-slug';
  const PATH1 = '/brands/summaries';
  const PATH2 = '/facilities/overviews/by-code';
  const PATH3 = '/facilities/overviews/by-slug';
  const PATH4 = '/facilities/summaries';
  const PATH5 = '/member/login';
  const PATH6 = '/member/logout';
  const PATH7 = '/member/profile';
  const PATH8 = '/search';
  const PATH9 = '/settings/basics/gallery-categories';
  const PATH10 = '/settings/currencies';
  const PATH11 = '/settings/global-site-settings';
  const PATH12 = '/special-pages';
  const PATH13 = '/special-pages/get-by-space';
  const PATH14 = '/special-pages/search-by-space';
  const GET = 'GET';
  const POST = 'POST';

  return {
    brands: {
      overviews: {
        by_slug: {
          _brandSlug: (val3: string) => {
            const prefix3 = `${PATH0}/${val3}`;

            return {
              /**
               * 指定のブランドスラッグのブランド概要を取得する
               * @returns ブランド概要
               */
              get: (option?: { query?: Methods_19ld5ay['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_19ld5ay['get']['resBody'], BasicHeaders, Methods_19ld5ay['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定のブランドスラッグのブランド概要を取得する
               * @returns ブランド概要
               */
              $get: (option?: { query?: Methods_19ld5ay['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_19ld5ay['get']['resBody'], BasicHeaders, Methods_19ld5ay['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_19ld5ay['get']['query'] } | undefined) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            };
          },
        },
      },
      summaries: {
        /**
         * 全てのブランド概略リストを取得する
         * @returns ブランド概略リスト
         */
        get: (option?: { query?: Methods_1q2mzsf['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1q2mzsf['get']['resBody'], BasicHeaders, Methods_1q2mzsf['get']['status']>(prefix, PATH1, GET, option).json(),
        /**
         * 全てのブランド概略リストを取得する
         * @returns ブランド概略リスト
         */
        $get: (option?: { query?: Methods_1q2mzsf['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1q2mzsf['get']['resBody'], BasicHeaders, Methods_1q2mzsf['get']['status']>(prefix, PATH1, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1q2mzsf['get']['query'] } | undefined) =>
          `${prefix}${PATH1}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    facilities: {
      overviews: {
        by_code: {
          _facilityCode: (val3: string) => {
            const prefix3 = `${PATH2}/${val3}`;

            return {
              /**
               * 指定のHOP連携施設コードの施設概要を取得する
               * @returns 施設概要
               */
              get: (option?: { query?: Methods_y6905x['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_y6905x['get']['resBody'], BasicHeaders, Methods_y6905x['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定のHOP連携施設コードの施設概要を取得する
               * @returns 施設概要
               */
              $get: (option?: { query?: Methods_y6905x['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_y6905x['get']['resBody'], BasicHeaders, Methods_y6905x['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_y6905x['get']['query'] } | undefined) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            };
          },
        },
        by_slug: {
          _hotelSlug: (val3: string) => {
            const prefix3 = `${PATH3}/${val3}`;

            return {
              /**
               * 指定の施設スラッグの施設概要を取得する
               * @returns 施設概要
               */
              get: (option?: { query?: Methods_v2ojx4['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_v2ojx4['get']['resBody'], BasicHeaders, Methods_v2ojx4['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定の施設スラッグの施設概要を取得する
               * @returns 施設概要
               */
              $get: (option?: { query?: Methods_v2ojx4['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_v2ojx4['get']['resBody'], BasicHeaders, Methods_v2ojx4['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_v2ojx4['get']['query'] } | undefined) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            };
          },
        },
      },
      summaries: {
        /**
         * 全ての施設概略リストを取得する
         * @returns 施設概略リスト
         */
        get: (option?: { query?: Methods_10pranw['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_10pranw['get']['resBody'], BasicHeaders, Methods_10pranw['get']['status']>(prefix, PATH4, GET, option).json(),
        /**
         * 全ての施設概略リストを取得する
         * @returns 施設概略リスト
         */
        $get: (option?: { query?: Methods_10pranw['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_10pranw['get']['resBody'], BasicHeaders, Methods_10pranw['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_10pranw['get']['query'] } | undefined) =>
          `${prefix}${PATH4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    member: {
      login: {
        /**
         * 会員ログイン認証を行う
         * @returns 星野リゾート会員情報
         */
        post: (option: { body: Methods_14xwhh2['post']['reqBody'], query?: Methods_14xwhh2['post']['query'] | undefined, config?: T | undefined }) =>
          fetch<Methods_14xwhh2['post']['resBody'], BasicHeaders, Methods_14xwhh2['post']['status']>(prefix, PATH5, POST, option).json(),
        /**
         * 会員ログイン認証を行う
         * @returns 星野リゾート会員情報
         */
        $post: (option: { body: Methods_14xwhh2['post']['reqBody'], query?: Methods_14xwhh2['post']['query'] | undefined, config?: T | undefined }) =>
          fetch<Methods_14xwhh2['post']['resBody'], BasicHeaders, Methods_14xwhh2['post']['status']>(prefix, PATH5, POST, option).json().then(r => r.body),
        $path: (option?: { method: 'post'; query: Methods_14xwhh2['post']['query'] } | undefined) =>
          `${prefix}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      logout: {
        /**
         * 会員ログアウト処理を行う
         * @returns ログアウト成功
         */
        post: (option?: { query?: Methods_yugit3['post']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_yugit3['post']['resBody'], BasicHeaders, Methods_yugit3['post']['status']>(prefix, PATH6, POST, option).json(),
        /**
         * 会員ログアウト処理を行う
         * @returns ログアウト成功
         */
        $post: (option?: { query?: Methods_yugit3['post']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_yugit3['post']['resBody'], BasicHeaders, Methods_yugit3['post']['status']>(prefix, PATH6, POST, option).json().then(r => r.body),
        $path: (option?: { method: 'post'; query: Methods_yugit3['post']['query'] } | undefined) =>
          `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      profile: {
        /**
         * 現在ログイン中の星野リゾート会員情報を取得する
         * @returns 星野リゾート会員情報
         */
        get: (option?: { query?: Methods_1cs8q36['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1cs8q36['get']['resBody'], BasicHeaders, Methods_1cs8q36['get']['status']>(prefix, PATH7, GET, option).json(),
        /**
         * 現在ログイン中の星野リゾート会員情報を取得する
         * @returns 星野リゾート会員情報
         */
        $get: (option?: { query?: Methods_1cs8q36['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1cs8q36['get']['resBody'], BasicHeaders, Methods_1cs8q36['get']['status']>(prefix, PATH7, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1cs8q36['get']['query'] } | undefined) =>
          `${prefix}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    search: {
      /**
       * 全文検索
       * @returns 全文検索結果
       */
      get: (option: { query: Methods_4ofto2['get']['query'], config?: T | undefined }) =>
        fetch<Methods_4ofto2['get']['resBody'], BasicHeaders, Methods_4ofto2['get']['status']>(prefix, PATH8, GET, option).json(),
      /**
       * 全文検索
       * @returns 全文検索結果
       */
      $get: (option: { query: Methods_4ofto2['get']['query'], config?: T | undefined }) =>
        fetch<Methods_4ofto2['get']['resBody'], BasicHeaders, Methods_4ofto2['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods_4ofto2['get']['query'] } | undefined) =>
        `${prefix}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
    settings: {
      basics: {
        gallery_categories: {
          /**
           * ギャラリーカテゴリリスト取得
           * @returns ギャラリーカテゴリリスト
           */
          get: (option?: { query?: Methods_le7gmx['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_le7gmx['get']['resBody'], BasicHeaders, Methods_le7gmx['get']['status']>(prefix, PATH9, GET, option).json(),
          /**
           * ギャラリーカテゴリリスト取得
           * @returns ギャラリーカテゴリリスト
           */
          $get: (option?: { query?: Methods_le7gmx['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_le7gmx['get']['resBody'], BasicHeaders, Methods_le7gmx['get']['status']>(prefix, PATH9, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_le7gmx['get']['query'] } | undefined) =>
            `${prefix}${PATH9}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      currencies: {
        /**
         * 通貨情報リストを取得する
         * @returns 通貨情報リスト
         */
        get: (option?: { query?: Methods_pl9tuj['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_pl9tuj['get']['resBody'], BasicHeaders, Methods_pl9tuj['get']['status']>(prefix, PATH10, GET, option).json(),
        /**
         * 通貨情報リストを取得する
         * @returns 通貨情報リスト
         */
        $get: (option?: { query?: Methods_pl9tuj['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_pl9tuj['get']['resBody'], BasicHeaders, Methods_pl9tuj['get']['status']>(prefix, PATH10, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_pl9tuj['get']['query'] } | undefined) =>
          `${prefix}${PATH10}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      global_site_settings: {
        /**
         * サイト共通設定を取得する
         * @returns サイト共通設定
         */
        get: (option?: { query?: Methods_1os4x0t['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1os4x0t['get']['resBody'], BasicHeaders, Methods_1os4x0t['get']['status']>(prefix, PATH11, GET, option).json(),
        /**
         * サイト共通設定を取得する
         * @returns サイト共通設定
         */
        $get: (option?: { query?: Methods_1os4x0t['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_1os4x0t['get']['resBody'], BasicHeaders, Methods_1os4x0t['get']['status']>(prefix, PATH11, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1os4x0t['get']['query'] } | undefined) =>
          `${prefix}${PATH11}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    special_pages: {
      get_by_space: {
        /**
         * 指定のスペース＆スラッグの特集ページを取得する
         * @returns 特集ページ
         */
        get: (option: { query: Methods_1a35v2c['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1a35v2c['get']['resBody'], BasicHeaders, Methods_1a35v2c['get']['status']>(prefix, PATH13, GET, option).json(),
        /**
         * 指定のスペース＆スラッグの特集ページを取得する
         * @returns 特集ページ
         */
        $get: (option: { query: Methods_1a35v2c['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1a35v2c['get']['resBody'], BasicHeaders, Methods_1a35v2c['get']['status']>(prefix, PATH13, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1a35v2c['get']['query'] } | undefined) =>
          `${prefix}${PATH13}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      search_by_space: {
        /**
         * 設置スペース別の特集ページを検索する
         * @returns 設置スペース別の特集ページ検索結果
         */
        get: (option?: { query?: Methods_2ectt2['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_2ectt2['get']['resBody'], BasicHeaders, Methods_2ectt2['get']['status']>(prefix, PATH14, GET, option).json(),
        /**
         * 設置スペース別の特集ページを検索する
         * @returns 設置スペース別の特集ページ検索結果
         */
        $get: (option?: { query?: Methods_2ectt2['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_2ectt2['get']['resBody'], BasicHeaders, Methods_2ectt2['get']['status']>(prefix, PATH14, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_2ectt2['get']['query'] } | undefined) =>
          `${prefix}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      /**
       * 特集ページ検索を検索する
       * @returns 特集ページ検索結果
       */
      get: (option?: { query?: Methods_rdms38['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods_rdms38['get']['resBody'], BasicHeaders, Methods_rdms38['get']['status']>(prefix, PATH12, GET, option).json(),
      /**
       * 特集ページ検索を検索する
       * @returns 特集ページ検索結果
       */
      $get: (option?: { query?: Methods_rdms38['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods_rdms38['get']['resBody'], BasicHeaders, Methods_rdms38['get']['status']>(prefix, PATH12, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods_rdms38['get']['query'] } | undefined) =>
        `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
