import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { HBtn } from '~/components';

export interface MyHotelLucyPromisesProps {}

export interface MyHotelLucyPromisesEmits {}

export interface MyHotelLucyPromisesScopedSlots {}

// TODO:
// data/brands/lucy/detail.ts から取得してデータを共用するようにしたい
// ---
// const brand = await $hotel.brandDetailBySlug(brand_slug);
// みたいに拾ってきたかったが、すんなりいかなかったので一旦ベタ書きしている
const promises = [
  'プライベートな寝室',
  'いつもの温水洗浄トイレ',
  'シャワー＆パウダールーム',
  '肉・魚・玉子の贅沢ごはん',
  'ホテル内にラストコンビニ',
  '充電・Wi-Fi 無制限',
];

@Component<MyHotelLucyPromisesRef>({
  name: 'MyHotelLucyPromises',
  render() {
    return (
      <div staticClass="my-hotel-lucy-promises">
        <div staticClass="my-hotel-lucy-promises__inner">
          <span staticClass="my-hotel-lucy-promises__lead">
            LUCYがいればきっと大丈夫
          </span>
          <h2 staticClass="my-hotel-lucy-promises__title">
            心地よい山時間のための
            <br />
            6つのプロミス
          </h2>

          <div staticClass="my-hotel-lucy-promises__container">
            <img
              staticClass="my-hotel-lucy-promises__illustration"
              src={this.$res.img(
                '/brands/lucy/brand_illustration_promise_01.png',
              )}
              alt=""
            />
            <div staticClass="my-hotel-lucy-promises__items">
              {promises.map((promise) => (
                <div staticClass="my-hotel-lucy-promises__item">
                  <img
                    staticClass="my-hotel-lucy-promises__icon"
                    src={this.$res.img('/brands/lucy/icon-thumbs-up.svg')}
                    alt=""
                  />
                  <span staticClass="my-hotel-lucy-promises__text">
                    {promise}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <HBtn
            staticClass="my-hotel-lucy-promises__btn"
            prependStack
            href={`/${this.$language.current}/brands/lucy/#brandPromises`}>
            詳しく見る
          </HBtn>
        </div>
      </div>
    );
  },
})
export default class MyHotelLucyPromisesRef
  extends Vue
  implements MyHotelLucyPromisesProps {}

export const TypedMyHotelLucyPromises = tsx
  .ofType<
    MyHotelLucyPromisesProps,
    MyHotelLucyPromisesEmits,
    MyHotelLucyPromisesScopedSlots
  >()
  .convert(MyHotelLucyPromisesRef);
