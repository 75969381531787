import Vue from 'vue';
import { ComputedBrandLinkItem } from './schemes';
import { HotelBrandBasicInfo } from '~/schemes';

/**
 * ブランドのリンクリストを生成する
 *
 * @param vm - Vueインスタンス
 * @param brand - ブランド基本情報
 * @returns - リンクのリスト
 */
export function createBrandLinks(
  vm: Vue,
  brand: HotelBrandBasicInfo,
): ComputedBrandLinkItem[] {
  const links: ComputedBrandLinkItem[] = [];

  /** ブランド設定で並び替えされたあとの後の施設リスト */
  const sortedHotels = vm.$hotel.hotelsByBrandId(brand.id);

  // 施設のリストから、リンクをどんどこ抽出する
  sortedHotels.forEach((hotel) => {
    // まず有効言語設定を探す
    const langSettings = hotel.availableLanguages.find(
      (lang) => lang.id === vm.$language.current,
    );

    if (!langSettings) {
      // 現在の言語で公開されていなければリンクを表示しない
      return;
    }

    // リンク先
    const hotelURL =
      langSettings.external || `/${vm.$language.current}/hotels/${hotel.slug}/`;

    const parsed = vm.$navigation.parseURL(hotelURL, {
      title: hotel.name,
    });

    // 施設名と施設の接頭辞
    const hotelNameWithPrefix = generateHotelNameWithPrefix(
      brand.name,
      hotel.name,
    );

    // 施設の営業状況をリストから取得
    const hotelStatus = vm.$commons.hotelStatuses.find(
      ({ value }) => value === hotel.status,
    );

    links.push({
      ...parsed,
      ...hotelNameWithPrefix,
      key: hotel.slug,
      address: hotel.brandAddress || '',
      status: hotelStatus ? hotelStatus.name : undefined,
    });
  });

  return links;
}

/**
 * 施設名と接頭辞を生成する
 * @param brandName ブランド名
 * @param hotelName 施設名
 * @returns 施設名と接頭辞
 */
function generateHotelNameWithPrefix(
  brandName: string,
  hotelName: string,
): Pick<ComputedBrandLinkItem, 'name' | 'prefix'> {
  // OMOやBEBの場合は接頭辞を取り出す
  if (brandName === 'OMO' || brandName === 'BEB') {
    // OMO7やBEB5のような接頭辞と、その後ろの文字列に分割する
    // 例: OMO関西空港 → OMO 関西空港, BEB5軽井沢 → BEB5 軽井沢
    const regexp = new RegExp(`(^${brandName}\\d?)(.+$)`);
    const matched = hotelName.match(regexp);

    if (!matched || matched.length < 3) {
      return {
        name: hotelName,
        prefix: '',
      };
    }

    return {
      name: matched[2].trim(),
      prefix: matched[1].trim(),
    };
  }

  // それ以外は リゾナーレトマム などの形式なので、先頭のブランド名を取り除く
  return {
    name: hotelName.replace(new RegExp(`^${brandName}`), '').trim(),
  };
}
