import './default.scss';

import { Component, mixins } from 'nuxt-property-decorator';
import { VStackContext, bodyScrollLock } from '@dadajam4/vue-stack';
import { HLayoutMixin } from './-layout-mixin';
import {
  HHeader,
  HHeaderRef,
  HGlobalFooter,
  HPortalFooter,
  HPortalFooterSpacer,
  HDrawer,
  HDrawerRef,
  HPortalTarget,
} from '~/components';
import { HotelBasicInfo } from '~/schemes';

@Component<HDefaultLayout>({
  name: 'HDefaultLayout',
  directives: {
    bodyScrollLock,
  },
  provide() {
    return {
      layout: this,
    };
  },
  render() {
    return (
      <div staticClass="h-app h-default-layout">
        <VStackContext />
        <HHeader ref="header" />
        <HPortalTarget
          staticClass="h-app__cover-portal"
          name="portal-app-cover-portal"
        />
        <div staticClass="h-main-wrapper">
          <main staticClass="h-main">
            <nuxt />
          </main>
          <HPortalFooter />
          <HDrawer ref="drawer" />
          <HGlobalFooter />
          <HPortalFooterSpacer />
        </div>
        <transition name="fade">
          {this.hasOverlayRequest && (
            <div
              v-body-scroll-lock={true}
              staticClass="h-default-layout__overlay"
            />
          )}
        </transition>
      </div>
    );
  },
})
export default class HDefaultLayout extends mixins<HLayoutMixin>(HLayoutMixin) {
  $refs!: {
    header: HHeaderRef;
    drawer: HDrawerRef;
  };

  hotel: HotelBasicInfo | null = null;

  private overlayRequests: any[] = [];

  get hasOverlayRequest() {
    return this.overlayRequests.length > 0;
  }

  overlayRequest(source: any) {
    if (!this.overlayRequests.includes(source)) {
      this.overlayRequests.push(source);
    }
  }

  releaseOverlayRequest(source: any) {
    const index = this.overlayRequests.indexOf(source);
    if (index !== -1) {
      this.overlayRequests.splice(index, 1);
    }
  }
}
