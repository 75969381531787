import './HHeader.scss';

import * as tsx from 'vue-tsx-support';
import { clickOutside } from '@dadajam4/vue-stack';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { HSearchDialog, HSearchDialogRef } from '../HSearchDialog';
import {
  HExpandTransition,
  HNotification,
  HHotelHeader,
  HBrandHeader,
  HHotelToBooking,
  HPortalItem,
  HBtn,
  HIcon,
} from '~/components';

const USE_SESSION_STORAGE = false;

export interface HHeaderProps {}

export interface HHeaderEmits {}

export interface HHeaderScopedSlots {}

@Component<HHeaderRef>({
  name: 'HHeader',
  directives: {
    clickOutside,
  },
  head() {
    const { hasNotification } = this;
    return {
      htmlAttrs: {
        'data-has-header-notification': hasNotification ? '1' : '0',
      },
    };
  },
  render() {
    const { hotel, brand, current } = this;
    const notification = this.$hotel.currentNotifications;
    const { drawerIsActive } = this.$ui;
    const suggestedTopicLinks =
      (current && current.suggestedTopicLinks) ?? undefined;

    return (
      <div
        staticClass="h-header"
        v-resize={(dimention: { width: number; height: number }) => {
          this.height = dimention.height;
        }}>
        {notification.length > 0 && (
          <HExpandTransition>
            <HNotification
              overlayMode={true}
              staticClass="h-header__notification"
              removable
              data={notification}
              suggestedTopicLinks={suggestedTopicLinks}
              v-show={
                !(USE_SESSION_STORAGE && !this.sessionDetected) &&
                !this.isRemoved
              }
              onClickRemove={(ev) => {
                ev.stopPropagation();
                this.remove();
              }}
            />
          </HExpandTransition>
        )}
        <div staticClass="h-header__body">
          <div staticClass="h-header__control">
            <button
              staticClass="h-header__toggle-drawer"
              class={{
                'h-header__toggle-drawer--active': drawerIsActive,
              }}
              type="button"
              onClick={(ev) => {
                ev.stopPropagation();
                this.$gfev.push({
                  category: this.$gfev.Category.Push,
                  action: drawerIsActive ? 'humberger_close' : 'humberger_open',
                  id: 'humberger',
                });
                this.$ui.softToggleDrawer();
              }}>
              <span staticClass="h-header__toggle-drawer__path h-header__toggle-drawer__path--1" />
              <span staticClass="h-header__toggle-drawer__path h-header__toggle-drawer__path--2" />
            </button>
          </div>
          {/* 全文検索ボタン */}
          <div staticClass="h-header__search__wide-wrapper">
            <HBtn staticClass="h-header__search" onClick={this.toggle}>
              <HIcon
                name="search"
                center
                staticClass="h-header__search__icon"
              />
            </HBtn>
          </div>
          <a
            staticClass="h-header__logo"
            href={this.$t('url.globalTop') as string}>
            <img
              staticClass="h-header__logo__node"
              src={this.logoSrc}
              alt={this.$t('name.hr') as string}
            />
          </a>

          {/* 施設ページあるいはブランドページの場合、ここでデフォルトのヘッダーに装飾を被せている */}
          {!!hotel && (
            <HHotelHeader staticClass="h-header__hotel" hotel={hotel} />
          )}
          {!hotel && !!brand && brand.useHeaderLogo && (
            <HBrandHeader staticClass="h-header__brand" brand={brand} />
          )}

          {/* 空室検索ボタン */}
          <div class="h-header__side">
            <HHotelToBooking
              staticClass="h-header__to-booking"
              brand={brand}
              hotel={hotel || undefined}
              eventId="fixedarea"
            />
          </div>

          {/**
           * 施設でもブランドでもない時のポータルフッターは仕方なくここでやっている
           * いつか特集ページ以外にも、CP系のページがやってきたらこのフッター出したくないページも出てくるかもだけど
           * いったんこうしている
           */}
          {!hotel && !brand && (
            <HPortalItem to="portal-footer">
              <HHotelToBooking
                staticClass="h-header__portal-footer"
                eventId="fixedarea"
              />
            </HPortalItem>
          )}
        </div>
        {/* 全文検索ダイアログ */}
        <HSearchDialog ref="search"></HSearchDialog>
      </div>
    );
  },
  created() {
    this.internalRemoved = this.checkNotificationRemoved();
  },
  mounted() {
    if (process.browser) {
      if (USE_SESSION_STORAGE) {
        this.sessionDetected = true;
      }
    }
  },
  beforeDestroy() {
    this.$ui.setHasNotification(false);
  },
  watch: {
    hasNotification: {
      handler(value: boolean) {
        this.$ui.setHasNotification(value);
      },
      immediate: true,
    },
  },
})
export class HHeaderRef extends Vue implements HHeaderProps {
  $refs!: {
    search: HSearchDialogRef;
  };

  private internalRemoved: boolean = false;
  private sessionDetected: boolean = false;

  get logoSrc() {
    return this.$res.img(`/common/logo-hr-${this.$language.current}.svg`);
  }

  get height() {
    return this.$ui.headerHeight;
  }

  set height(height: number) {
    this.$ui.setHeaderHeight(height);
  }

  get hotel() {
    return this.$hotel.current;
  }

  get brand() {
    return this.$hotel.currentBrand;
  }

  get isRemoved() {
    return this.internalRemoved;
  }

  get hasNotification() {
    if (USE_SESSION_STORAGE && !this.sessionDetected) return false;
    const { notifications } = this;
    return notifications.length > 0 && !this.isRemoved;
  }

  get notifications() {
    return this.$hotel.currentNotifications;
  }

  get notificationId() {
    let result = 0;
    this.notifications.forEach((row) => {
      result += strToCodeSum(row.createdAt);
    });
    return result;
  }

  get current() {
    return this.$hotel.current;
  }

  remove() {
    if (!this.isRemoved) {
      this.internalRemoved = true;
      this.setNotificationRemoved();
    }
  }

  private checkNotificationRemoved() {
    if (USE_SESSION_STORAGE) {
      return checkSessionRemoved(this.notificationId);
    }

    // eslint-disable-next-line eqeqeq
    return this.$cookies.get(`GF_NR_${this.notificationId}`) == '1';
  }

  private setNotificationRemoved() {
    if (USE_SESSION_STORAGE) {
      return setSessionRemoved(this.notificationId);
    }
    this.$cookies.set(`GF_NR_${this.notificationId}`, '1');
  }

  /** 全文検索ダイアログ操作 */
  private toggle() {
    const { search } = this.$refs;
    search.modalIsActive ? search.close() : search.show();
  }
}

function strToCodeSum(str?: string | null) {
  if (str == null) return 0;
  let result = 0;
  for (let i = 0, l = str.length; i < l; i++) {
    result += str.charCodeAt(i);
  }
  return result;
}

function checkSessionRemoved(id: string | number) {
  if (typeof sessionStorage === 'undefined') return false;
  return sessionStorage.getItem(`GF_NR_${id}`) === '1';
}

function setSessionRemoved(id: string | number) {
  if (typeof sessionStorage === 'undefined') return;
  sessionStorage.setItem(`GF_NR_${id}`, '1');
}

export const HHeader = tsx
  .ofType<HHeaderProps, HHeaderEmits, HHeaderScopedSlots>()
  .convert(HHeaderRef);
