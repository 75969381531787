import Vue from 'vue';
import { Context, Plugin } from '@nuxt/types';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import axios, { AxiosRequestConfig } from 'axios';
import {
  HotelBasicInfo,
  HotelDetail,
  CacheJSONBase,
  HotelLocationLS,
  HotelLocationGeneratedInfoLS,
  SpecialPage,
  SpecialPagePriority,
  SearchSpCondition,
  SiteSettings,
  HotelStatus,
} from '~/schemes';
import { asyncCombine } from '~/lib/async-supports';
import { isPreviewHost } from '~/utils/preview';

declare module 'vue/types/vue' {
  export interface Vue {
    $dataBucket: DataBucket;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $dataBucket: DataBucket;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $dataBucket: DataBucket;
  }
}

export class DataBucket {
  readonly context: Context;
  readonly adapter: NuxtAxiosInstance;
  readonly isPreview: boolean;
  readonly host: string;

  get cacheHash() {
    return this.context.$env.dataVersion;
  }

  get prefix() {
    return process.browser ? location.origin : 'http://localhost:3000';
  }

  get baseUrl(): string {
    return `${this.prefix}/${this.context.$env.dataBucket}`;
  }

  get lang() {
    return this.context.$language.current;
  }

  constructor(context: Context) {
    this.context = context;

    let host: string;
    if (process.server) {
      host = context.req.headers.host || '';
      context.store.commit('SET_HOST', host);
    } else {
      host = context.store.state.host;
    }

    this.host = host;

    this.isPreview = isPreviewHost(host, context.$env.isDevelop);

    this.adapter = this.context.$axios.create({
      baseURL: this.baseUrl,
      withCredentials: true,
    }) as NuxtAxiosInstance;

    this.adapter.defaults.headers.common = {
      ...this.adapter.defaults.headers.common,
      ...(this.isPreview
        ? {
            'acms-preview': '1',
          }
        : undefined),
    };
    delete this.adapter.defaults.headers.authorization;

    this._get = asyncCombine(this._get.bind(this));
  }

  createfetchParams() {
    return {
      lang: this.lang,
      _: this.cacheHash,
    };
  }

  createUrl(path: string) {
    return this.baseUrl.replace(/\/$/, '') + '/' + path.replace(/^\//, '');
  }

  headersUtil(url: string) {
    return this.adapter.$get<any>('/__head__', { params: { url } });
  }

  private _get<T = any>(
    url: string,
    opts: AxiosRequestConfig = {},
  ): Promise<T> {
    return this.adapter.$get<T>(url, opts).catch((_err) => {
      if (!this.isPreview) throw _err;
      if (
        axios.isAxiosError(_err) &&
        _err.response &&
        _err.response.status === 403
      ) {
        const { isDevelop, isTest } = this.context.$env;
        const protocol = isDevelop ? 'http' : 'https';

        const currentUrl = `${protocol}://${this.host}${this.context.route.fullPath}`;

        const ADMIN_HOST = isDevelop
          ? 'http://localhost:4000'
          : `https://${
              isTest ? 'test-' : ''
            }hr-acco-cms.hoshinoresorts.systems`;
        const redirectTo = `${ADMIN_HOST}/login?cb=${currentUrl}`;

        if (process.server) {
          this.context.redirect(redirectTo);
        } else {
          location.replace(redirectTo);
        }
      }
      throw _err;
    });
  }

  get<T = any>(path: string, opts: AxiosRequestConfig = {}): Promise<T> {
    const url = this.createUrl(path);
    return this._get(url, {
      params: {
        ...this.createfetchParams(),
        ...opts.params,
      },
    });
  }

  post<T = any>(path: string, data?: any): Promise<T> {
    const url = this.createUrl(path);
    return this.adapter.$post<T>(url, data);
  }

  checkAuth() {
    return this.get<'ok'>('/__check__').then((result) => {
      return result === 'ok';
    });
  }

  getCmsCaches() {
    return this.get<{ caches: CacheJSONBase[] }>('/__cms_caches__');
  }

  clearCmsCaches(Keys: string[]) {
    const url = this.createUrl('/__cms_caches__/invalidation');
    return this.adapter.$post<void>(url, {
      Keys,
    });
  }

  getHotels(): Promise<HotelBasicInfo[]> {
    return this.get('/hotels');
  }

  getHotelBySlug(slug: string) {
    return this.get<Omit<HotelDetail, 'ygets'>>(`/hotels/${slug}`);
  }

  /**
   * 特集ページのデータ取得
   *
   * globalの場合はtargetが必要ないので条件分岐で出し分け
   */
  getSpecialPage(condition: {
    space: SpecialPage['space'];
    target?: string;
    slug: string;
  }): Promise<SpecialPage> {
    return condition.space === 'global'
      ? this.get(`/special-pages/global/${condition.slug}`)
      : this.get(
          `/special-pages/${condition.space}/${condition.target}/${condition.slug}`,
        );
  }

  /**
   * 特集ページ一覧取得
   */
  searchSpecialPages(
    searchParams: Partial<SearchSpCondition>,
  ): Promise<SpecialPagePriority[]> {
    return this.get(`/special-pages/`, { params: searchParams });
  }

  async getHotelLocationLS(
    slug: string,
  ): Promise<{
    location: HotelLocationLS;
    generated: HotelLocationGeneratedInfoLS;
  }> {
    const baseImported = await this.get<{
      default: HotelLocationLS;
    }>(`/hotels/${slug}/location`).catch((err) => {
      if (err.response && err.response.status === 404) {
        return { default: {} } as {
          default: HotelLocationLS;
        };
      }
      throw err;
    });
    const location = (baseImported && baseImported.default) || {};
    const generatedImported = await this.get<{
      default: HotelLocationGeneratedInfoLS;
    }>(`/hotels/${slug}/.generated/location.json`).catch((err) => {
      if (err.response && err.response.status === 404) {
        return { default: {} };
      }
      throw err;
    });

    const generated = (generatedImported && generatedImported.default) || {};
    return { location, generated };
  }

  /** CMSのサイト設定を取得 */
  getSiteSettings(): Promise<SiteSettings> {
    return this.get('/commons/site-settings');
  }

  /** 施設の営業状況リストを取得 */
  getHotelStatuses(): Promise<HotelStatus[]> {
    return this.get('/commons/hotel-statuses');
  }
}

const plugin: Plugin = (context, inject) => {
  const dataBucket = new DataBucket(context);
  context.$dataBucket = dataBucket;
  inject('dataBucket', dataBucket);
};

export default plugin;
