import './HHotelToBookingAction.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { toInfo } from './utils';
import {
  BookingActionButtonBgColor,
  BookingActionButtonLetterColor,
  HotelBasicInfo,
  HotelBrandBasicInfo,
} from '~/schemes';
import { mergeVNodeData } from '~/helpers';
import { HBtn } from '~/components';
import { GFEventCategory } from '~/plugins/gfev';

export interface CustomSettings {
  customStyle?: {
    backgroundColor: BookingActionButtonBgColor;
    color: BookingActionButtonLetterColor;
    borderColor: BookingActionButtonBgColor;
  };
  customUrl?: string;
  customText?: string;
}

export const HHotelToBookingAction = tsx.component({
  name: 'HHotelToBookingAction',
  functional: true,

  props: {
    hotel: Object as PropType<HotelBasicInfo | undefined>,
    brand: Object as PropType<HotelBrandBasicInfo | undefined>,
    customSettings: Object as PropType<CustomSettings | undefined>,
    eventId: String,
  },

  render(h, { data, props, parent }): VNode {
    const { eventId } = props;

    const { to, hasRoomSearchLink, linkAttrs, externalBookingSite } = toInfo(
      parent,
      props,
    );

    const customUrl =
      (props.customSettings && props.customSettings.customUrl) || undefined;
    const customStyle =
      (props.customSettings && props.customSettings.customStyle) || undefined;
    const customText =
      (props.customSettings && props.customSettings.customText) || undefined;

    if (!hasRoomSearchLink) return undefined as any;

    let evAction = '';
    if (process.browser) {
      if (to && !customUrl) {
        evAction =
          location.origin + parent.$router.resolve(to).resolved.fullPath;
      } else if (customUrl) {
        evAction = customUrl;
      } else if (externalBookingSite) {
        evAction = externalBookingSite;
      }
    }

    const directives =
      (eventId && [
        {
          name: 'ev',
          value: {
            category: GFEventCategory.Link,
            action: evAction,
            eventId,
          },
        },
      ]) ||
      undefined;

    return (
      <HBtn
        {...(mergeVNodeData(data, {
          staticClass: 'h-hotel-to-booking-action',
          attrs: customUrl
            ? {
                href: customUrl,
                target: '_blank',
              }
            : linkAttrs,
          style: customStyle,
          directives,
        }) as any)}
        to={customUrl ? undefined : to}
        color={customStyle ? undefined : 'primary'}
        stack={
          customText ? undefined : (parent.$t('guide.bestRateShort') as string)
        }>
        {customText || parent.$t('name.vacancySearch')}
      </HBtn>
    );
  },
});
