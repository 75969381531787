import './HLeadText.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, LeadTextModule } from '~/schemes';
import { VerticalSpacing } from '~/plugins/gf-api/client/@types';

export const HLeadText = tsx.component({
  name: 'HLeadText',
  functional: true,

  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<LeadTextModule['properties'], AvailableLanguage>
      >,
      required: true,
    },
    verticalSpacing: {
      type: String as PropType<VerticalSpacing>,
      required: false,
    },
  },

  render(h, { props }): VNode {
    const verticalSpacingClassName = props.verticalSpacing
      ? `v-lead-text-module-vertical-spacing--${props.verticalSpacing}`
      : '';
    return (
      <div
        v-wysiwyg={props.value.text}
        class={[
          'h-container',
          'h-container--sm',
          `v-lead-text-module`,
          `v-lead-text-module-size--${props.value.size}`,
          verticalSpacingClassName,
        ]}></div>
    );
  },
});
