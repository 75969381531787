import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import './HRegionNavigation.scss';

export interface HRegionNavigationProps {}

export interface HRegionNavigationEmits {}

export interface HRegionNavigationScopedSlots {}

@Component<HRegionNavigationRef>({
  name: 'HRegionNavigation',
  render() {
    const { regions } = this;

    // TODO: リンク先を設定する
    return (
      <div staticClass="h-region-navigation">
        <h3 staticClass="h-region-navigation__title">
          {this.$i18n.t('label.footer.regions')}
        </h3>
        {regions.map((region, i) => (
          <span staticClass="h-region-navigation__item" key={region.key}>
            <a staticClass="h-region-navigation__item__link" href={region.href}>
              {this.$i18n.t(`label.regions.${region.key}`)}
            </a>
            {i !== regions.length - 1 && (
              <span staticClass="h-region-navigation__item__separator">|</span>
            )}
          </span>
        ))}
      </div>
    );
  },
})
export class HRegionNavigationRef
  extends Vue
  implements HRegionNavigationProps {
  get regions() {
    const lang = this.$language.current;

    return [
      {
        key: 'hokkaido',
        href: `/${lang}/sp/regions/hokkaido/`,
      },
      {
        key: 'tohoku',
        href: `/${lang}/sp/regions/tohoku/`,
      },
      {
        key: 'hokurikuKoushinetsu',
        href: `/${lang}/sp/regions/hokuriku_koushinetsu/`,
      },
      {
        key: 'kanto',
        href: `/${lang}/sp/regions/kanto/`,
      },
      {
        key: 'tokai',
        href: `/${lang}/sp/regions/tokai/`,
      },
      {
        key: 'kinki',
        href: `/${lang}/sp/regions/kinki/`,
      },
      {
        key: 'kyushu',
        href: `/${lang}/sp/regions/kyushu/`,
      },
      {
        key: 'chugokuShikoku',
        href: `/${lang}/sp/regions/chugoku_shikoku/`,
      },
      {
        key: 'okinawa',
        href: `/${lang}/sp/regions/okinawa/`,
      },
      {
        key: 'overseas',
        href: `/${lang}/sp/regions/outsidejapan/`,
      },
    ];
  }
}

export const HRegionNavigation = tsx
  .ofType<
    HRegionNavigationProps,
    HRegionNavigationEmits,
    HRegionNavigationScopedSlots
  >()
  .convert(HRegionNavigationRef);
