import Vue, { VNodeChildren } from 'vue';
import { RawLocation } from 'vue-router';
import { HotelIntroductionProperties } from './schemes';
import { HotelBasicInfo } from '~/schemes';

interface ExtractedImages {
  lg?: string | null;
  md?: string | null;
}

interface Action {
  key: string;
  link: {
    attrs?: {
      href?: string;
      target?: string;
    };
    props?: {
      to?: RawLocation;
    };
  };
  // location: RawLocation;
  label: VNodeChildren;
}

/**
 * 表示用の施設紹介プロパティ
 *
 * * 選択施設が考慮されている
 * * 画像URLが平坦化されている
 */
export interface ExtractedHotelIntroductionDisplayProperties
  extends HotelIntroductionProperties {
  hotel?: HotelBasicInfo;
  images?: ExtractedImages | null;
  actions: Action[];
}

/**
 * 施設紹介情報の表示用データを取得する
 *
 * * 施設が選択されていて、編集中フラグがOFFで、なおかつその施設が存在していれば紹介データは施設マスタの方を採用する
 *
 * @param vm - Vueインスタンス
 * @param source - オリジナルの施設紹介プロパティ
 * @returns - 表示用に補正された施設紹介プロパティ
 */
export function toHotelIntroductionDisplayProperties(
  vm: Vue,
  source: HotelIntroductionProperties,
): ExtractedHotelIntroductionDisplayProperties {
  const {
    hotelId,
    isCustomize,
    image: _image,
    displayRoomSearchLink,
    brandAddress: _brandAddress,
  } = source;
  const hotel = hotelId ? vm.$hotel.getById(hotelId) : undefined;
  const hotelIntroduction = hotel && hotel.introduction;

  const image =
    !isCustomize && hotelIntroduction ? hotelIntroduction.image : _image;

  const images: ExtractedImages | null | undefined = image && {
    lg: image.derived && image.derived.lg && image.derived.lg.url,
    md: image.derived && image.derived.md && image.derived.md.url,
  };

  const brandAddress = isCustomize
    ? _brandAddress
    : hotel && hotel.brandAddress;

  const actions: Action[] = [];

  if (hotel) {
    (() => {
      // 施設サイトの遷移先を決定する
      const currentLang = vm.$language.current;
      const availableLanguage = hotel.availableLanguages.find(
        (l) => l.id === currentLang,
      );
      if (!availableLanguage) return;

      // 外部URL登録があればそちらを優先する
      const { external } = availableLanguage;

      actions.push({
        key: 'site',
        link: external
          ? {
              attrs: {
                href: external,
                target: '_blank',
              },
            }
          : {
              props: {
                to: vm.$hotel.location('/', hotel),
              },
            },
        label: vm.$t('label.hotelSite') as string,
      });
    })();

    // 宿GETS or 外部予約サイト
    const { ygetsId, externalBookingSite } = hotel;
    const bookingLocation =
      (ygetsId && vm.$hotel.createYgetsURL(ygetsId)) || externalBookingSite;
    if (bookingLocation && displayRoomSearchLink) {
      actions.push({
        key: 'ygets',
        link: {
          attrs: {
            href: bookingLocation,
            target: '_blank',
          },
        },
        label: vm.$t('name.vacancySearchShort') as string,
      });
    }
  }

  const extracted: ExtractedHotelIntroductionDisplayProperties = {
    ...source,
    images,
    hotel,
    actions,
    brandAddress,
  };
  if (!isCustomize && hotelIntroduction) {
    Object.assign(extracted, hotelIntroduction);
  }
  return extracted;
}
