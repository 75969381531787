import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MyHotelInstantPhoto } from '../MyHotelInstantPhoto';

export interface MyHotelFeatureMiniGalleryProps {
  images: string[];
}

@Component<MyHotelFeatureMiniGalleryRef>({
  name: 'MyHotelFeatureMiniGallery',
  render() {
    const { images, isLucyTheme } = this;

    return (
      <div staticClass="my-hotel-feature-mini-gallery">
        {images.map((image, index) => {
          // LUCYの場合
          if (isLucyTheme) {
            return (
              <MyHotelInstantPhoto
                src={image}
                axis="horizontal"
                class="my-hotel-feature-mini-gallery__photo"
                key={index}
              />
            );
          }

          // TODO: もしデフォルトテーマで使うならここに実装
          return (
            <img
              key={index}
              src={image}
              class="my-hotel-feature-mini-gallery__photo"
            />
          );
        })}
      </div>
    );
  },
})
export class MyHotelFeatureMiniGalleryRef
  extends Vue
  implements MyHotelFeatureMiniGalleryProps {
  @Prop({ type: Array, required: true }) readonly images!: string[];

  get isLucyTheme() {
    return this.$theme.current.name === 'lucy';
  }
}

export interface MyHotelFeatureMiniGalleryEmits {}
export interface MyHotelFeatureMiniGalleryScopedSlots {}

export const MyHotelFeatureMiniGallery = tsx
  .ofType<
    MyHotelFeatureMiniGalleryProps,
    MyHotelFeatureMiniGalleryEmits,
    MyHotelFeatureMiniGalleryScopedSlots
  >()
  .convert(MyHotelFeatureMiniGalleryRef);
