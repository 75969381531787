import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelAdditionalData } from '~/schemes';
import { HHtmlInjector } from '~/components';

export interface MyHotelAdditionalDataProps {
  items: HotelAdditionalData[];
}

export interface MyHotelAdditionalDataEmits {}

export interface MyHotelAdditionalDataScopedSlots {}

@Component<MyHotelAdditionalDataRef>({
  name: 'MyHotelAdditionalData',
  render() {
    return (
      <div class="my-hotel-additional-data">
        <div class="my-hotel-additional-data__title" />
        {this.items.map((item) => {
          // 念のためチェックしておく
          if (!item.html) {
            return null;
          }

          return (
            <div class="my-hotel-additional-data__item">
              {item.icon && (
                <img
                  class="my-hotel-additional-data__icon"
                  src={item.icon}
                  alt="アイコン"
                />
              )}
              <HHtmlInjector
                class="my-hotel-additional-data__text"
                html={item.html}
              />
            </div>
          );
        })}
      </div>
    );
  },
})
export default class MyHotelAdditionalDataRef
  extends Vue
  implements MyHotelAdditionalDataProps {
  @Prop({ type: Array, required: true }) items!: HotelAdditionalData[];
}

export const TypedMyHotelAdditionalData = tsx
  .ofType<
    MyHotelAdditionalDataProps,
    MyHotelAdditionalDataEmits,
    MyHotelAdditionalDataScopedSlots
  >()
  .convert(MyHotelAdditionalDataRef);
