import { AvailableLanguage } from './language';
import { GeneralOption } from './general';
import { Hyperlink } from './';
import type {
  ActivityKeyword as CMSActivityKeyword,
  // HotelActivityAttractiveItem as CMSActivityAttractiveItem,
} from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
/**
 * 対象年齢
 */
export enum ActivityTargetAge {
  /**
   * 年齢制限なし
   */
  All = '1',

  /**
   * 範囲指定
   */
  Range = '2',

  /**
   * 親子
   */
  ParentAndChild = '5',

  /**
   * 大人
   */
  Adult = '6',
}

/**
 * 対象年齢オプション
 */
export interface ActivityTargetAgeOption extends GeneralOption<string> {
  range: {
    from: number;
    to: number;
  } | null;
}

/**
 * 予約要否区分
 *
 * @FIXME
 * * すでにCMSで管理機能を提供しているので、ここでハードコードしているのはおかしい
 */
export enum ActivityReservationRequired {
  /**
   * 予約不要
   */
  Elective = '1',

  /**
   * 要予約
   */
  Required = '2',
}

/**
 * 予約要否区分オプション
 *
 * @see {@link ActivityReservationRequired}
 */
export interface ActivityReservationRequiredOption
  extends GeneralOption<ActivityReservationRequired> {}

/**
 * 料金区分
 *
 * @FIXME
 *
 * * この項目は「無料」のもののみ名前をひっぱる処理になっているが、CMSでマスタ管理機能を提供しているので、この処理は本来おかしい
 * * CMSで画面に出すか出さないか設定できるようにするか、有料・無料の区分をboolフラグのようにしないと概念としておかしい
 */
export enum ActivityChargeCategory {
  /**
   * 無料
   */
  NoCharge = '1',

  /**
   * 有料
   */
  Charge = '2',
}

/**
 * 料金区分オプション
 *
 * @see {@link ActivityChargeCategory}
 */
export interface ActivityChargeCategoryOption
  extends GeneralOption<ActivityChargeCategory> {}

/**
 * アクティビティ共通設定
 */
export interface ActivityCommons {
  /** 予約要否 */
  reservationRequiredOptions: ActivityReservationRequiredOption[];
  /** 料金区分 */
  chargeCategoryOptions: ActivityChargeCategoryOption[];
}

/**
 * アクティビティ所用時間
 */
export interface ActivityTimeRequired {
  minutes: number | null;
  text: string | null;
}

/**
 * アクティビティ
 */
export interface Activity {
  /** ID */
  id: string;

  /** 名前 */
  name: string;

  /**
   * タイトル
   *
   * * 下層ページ用
   */
  title: string;

  /** 説明文 */
  description: string;

  /**
   * 期間
   * - nullで通年
   */
  period: {
    from: string | null;
    to: string | null;
  } | null;

  /**
   * 期間カスタムテキスト
   * ※これを設定しておくと自動計算される期間表示を上書きします
   */
  periodText: string | null;

  /**
   * 期間補助テキスト
   * period、またはperiodTextの表示に追記されます
   */
  periodMemo: string | null;

  /**
   * 対象年齢区分
   */
  targetAge: ActivityTargetAge;

  /**
   * 対象年齢範囲
   */
  targetAgeRange: {
    from?: number | null;
    to?: number | null;
  } | null;

  /**
   * 料金区分
   */
  chargeCategory: string;

  /**
   * 予約要否
   */
  reservationRequired: string;

  /**
   * 料金
   */
  priceInfo: string | null;

  /**
   * 所要時間
   */
  timeRequired: ActivityTimeRequired;

  /**
   * 開催時間
   */
  times: string | null;

  /**
   * 集合場所
   */
  place: string | null;

  /**
   * 申し込み期限
   */
  applicationDeadline: string | null;

  /**
   * 定員
   */
  capacity: string | null;

  /**
   * 開催言語
   */
  hostLanguage: AvailableLanguage[] | null;

  /**
   * 持ち物
   */
  belongings: string | null;

  /**
   * 含まれる内容
   */
  includedContent: string | null;

  /**
   * 注意事項
   */
  precautions: string | null;

  /**
   * スケジュール
   */
  schedule: string | null;

  /** 予約URL */
  reservationUrl: string | null;

  /** サムネイル画像URL */
  thumb: string;

  /** メインビジュアル画像URL */
  mainImage: string;

  /** サブ画像URL */
  subImage: string | null;

  /**
   * ピン留めフラグ
   */
  pinned?: boolean;

  keywords: CMSActivityKeyword['value'][];

  /** 指定の特集ページに遷移する場合はtrue */
  isTargetPageNavigation?: boolean;

  /** 遷移する特集ページのリンク情報 */
  targetPageNavigation?: Hyperlink;

  attractiveItems: ActivityAttractiveItem[];

  /** 後注 */
  footnote?: string;
}

/**
 *  アクティビティキーワード
 */
export interface ActivityKeyword
  extends ResolvedLanugageData<CMSActivityKeyword, AvailableLanguage> {}

/**
 *  アクティビティ一覧をリクエストした結果
 *
 * * 検索でキーワードを表示できるようにキーワードも取得されている
 */
export interface ActivitiesRequestResult {
  /**
   *  アクティビティ一覧
   */
  activities: Activity[];

  /**
   * 取得したアクティビティに紐づくすべてのキーワードのリスト
   *
   * * 重複ないようにフィルタされている
   * * マスタリストの順番にソートされている
   */
  matchedKeywords: ActivityKeyword[];
}

/** 魅力紹介インターフェース */
export interface ActivityAttractiveItem {
  key: string;
  /** 画像 */
  images: string[];
  /** タイトル */
  title: string;
  /** 紹介文 */
  introductionText: string;
}
