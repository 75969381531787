import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

export interface MyHotelInstantPhotoProps {
  src: string;
  caption?: string;
  axis: 'vertical' | 'horizontal';
  /**
   * 要素を重ねるかどうか
   */
  isStack?: boolean;
  /**
   * 後ろの重なり部分の傾きの角度
   * (これを指定すると重なり部分の要素が作成されます)
   */
  stackTiltAngle?: number;
  /**
   * 画像の基準位置
   */
  position?: string;
}

export interface MyHotelInstantPhotoEmits {}

export interface MyHotelInstantPhotoScopedSlots {}

@Component<MyHotelInstantPhotoRef>({
  name: 'MyHotelInstantPhoto',
  render() {
    const baseClass = 'my-hotel-instant-photo';
    const stackClass = `${baseClass}--stack`;

    return (
      <div
        staticClass={baseClass}
        class={this.isStack && { [stackClass]: !!this.stackTiltAngle }}
        style={this.wrapperStyle}>
        <div staticClass="my-hotel-instant-photo__inner">
          <img
            staticClass={`${baseClass}__img`}
            class={`${baseClass}__img--${this.axis}`}
            src={this.src}
            alt=""
            style={this.imageStyle}
          />
          {this.caption && (
            <span
              staticClass={`${baseClass}__caption`}
              class={`${baseClass}__caption--${this.axis}`}>
              {this.caption}
            </span>
          )}
        </div>
      </div>
    );
  },
})
export default class MyHotelInstantPhotoRef
  extends Vue
  implements MyHotelInstantPhotoProps {
  @Prop({ type: String, required: true }) src!: string;
  @Prop({ type: String }) caption?: string;
  @Prop({ type: String, required: true }) axis!: 'vertical' | 'horizontal';
  @Prop({ type: Boolean, default: true }) isStack?: boolean;
  @Prop({ type: Number }) stackTiltAngle?: number;
  @Prop({ type: String }) position?: string;

  get wrapperStyle() {
    return {
      '--tilt-angle': this.stackTiltAngle
        ? `${this.stackTiltAngle}deg`
        : undefined,
    };
  }

  get imageStyle() {
    return {
      'object-position': this.position || 'center',
    };
  }
}

export const TypedMyHotelInstantPhoto = tsx
  .ofType<
    MyHotelInstantPhotoProps,
    MyHotelInstantPhotoEmits,
    MyHotelInstantPhotoScopedSlots
  >()
  .convert(MyHotelInstantPhotoRef);
