import './HSearchDialog.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Model } from 'vue-property-decorator';
import { clickOutside } from '@dadajam4/vue-stack';
import { debounce } from 'lodash';
import { HDialog, HPathIcon, HProgressCircular } from '~/components';
import { SearchTextResponse, Content, CONTENT_TYPE } from '~/schemes';
import { RawSearchTextParams } from '~/plugins/gf-api/client/@types';

/** 1文字でも検索するワード */
const ALLOW_SINGLE_WORDS = ['界'];

interface SearchParams extends Pick<RawSearchTextParams, 'page' | 'query'> {}

export interface HSearchDialogProps {
  value?: boolean;
}

export interface HSearchDialogEmits {
  onInput: boolean;
}

export interface HSearchDialogScopedSlots {}

/** 全文検索ダイアログ */
@Component<HSearchDialogRef>({
  name: 'HSearchDialog',
  directives: {
    clickOutside,
  },
  render() {
    return (
      <HDialog
        contentClass="h-search-dialog"
        contentStyle={{
          '--headerHeight': `${this.$ui.internalHeaderHeight}px`,
        }}
        active={this.modalIsActive}
        backdrop
        onChange={(active) => {
          this.modalIsActive = active;
        }}
        scopedSlots={{
          header: () => (
            <button
              staticClass="h-search-dialog__close"
              type="button"
              onClick={this.close}>
              <HPathIcon
                staticClass="h-search-dialog__close__icon"
                name="close"
              />
            </button>
          ),
        }}>
        {/* 検索ワード入力エリア */}
        <input
          type="text"
          v-model={this.searchQuery}
          placeholder={this.$t('search.placeholder') as string}
          class="h-search-dialog__input"
          onInput={this.debouncedSearch}
        />
        {/* 検索結果表示エリア */}
        <div class="h-search-dialog-content">
          {Object.keys(this.groupedResults) && (
            <ul class="h-search-dialog-content__list">
              {Object.keys(this.groupedResults).map((type) => (
                <li class="h-search-dialog-content__type">
                  <strong>{this.$t(`search.types.${type}`)}</strong>
                  <ul>
                    {this.groupedResults[type].map((result) => (
                      <li class="h-search-dialog-content__item">
                        <a
                          href={result.url}
                          target="_blank"
                          rel="noopener noreferrer">
                          {result.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          )}
          {/* 擬似要素 - ここまでスクロールしたら次ページを検索する */}
          {this.cursor && (
            <div
              v-inview={{
                in: () => {
                  if (!this.isLoading) {
                    this.onSearch(false);
                  }
                },
              }}>
              {this.isLoading && (
                <HProgressCircular
                  staticClass="h-search-dialog-content__loading"
                  indeterminate
                  width={2}
                />
              )}
            </div>
          )}
        </div>
      </HDialog>
    );
  },
  watch: {
    value(value: boolean) {
      this.internalModalActive = value;
    },
  },
})
export class HSearchDialogRef extends Vue implements HSearchDialogProps {
  @Model('input', { type: Boolean }) readonly value!: boolean;

  private internalModalActive: boolean = this.value;
  /** 次ページ有無 */
  private cursor: string | undefined = '';
  private isLoading = false;
  /** 検索クエリ */
  private searchQuery: string = '';
  /** ダミーの検索結果 */
  private searchResults: Content[] = [];
  /** グループ化された検索結果 */
  private groupedResults: Partial<Record<CONTENT_TYPE, Content[]>> = {};

  // 一定間隔毎の検索処理
  private debouncedSearch = debounce(() => this.onSearch(true), 500);

  get modalIsActive() {
    return this.internalModalActive;
  }

  set modalIsActive(modalIsActive) {
    if (this.internalModalActive !== modalIsActive) {
      this.internalModalActive = modalIsActive;
      this.$emit('input', modalIsActive);
    }
  }

  show() {
    this.modalIsActive = true;
  }

  close() {
    this.modalIsActive = false;
  }

  /** 検索処理 */
  async onSearch(reset: boolean = false) {
    // 検索キーワードがない場合は処理終了
    if (this.searchQuery.length === 0) {
      this.reset();
      return;
    }
    if (
      this.searchQuery.length >= 2 ||
      (this.searchQuery.length === 1 &&
        ALLOW_SINGLE_WORDS.includes(this.searchQuery))
    ) {
      if (reset) this.reset();
      this.isLoading = true;
      const response = await this.search({
        query: this.searchQuery,
      });
      const { contents, cursor } = response;
      this.cursor = cursor;
      this.searchResults = [...this.searchResults, ...contents];
      this.groupedResults = this.groupByContentType(this.searchResults);
      this.isLoading = false;
    }
  }

  /**
   * 全文検索処理
   * @returns 検索結果
   */
  async search(searchParams: SearchParams): Promise<SearchTextResponse> {
    const { query } = searchParams;
    const results = await this.$gfAPI.search.$get({
      query: {
        lang: this.$language.current,
        query,
        cursor: this.cursor,
        limit: 10,
        visibility: this.$dataBucket.isPreview ? 'pre' : 'open',
      },
    });
    return results;
  }

  private groupByContentType(results: Content[]) {
    return results.reduce((acc, result) => {
      if (!acc[result.type]) {
        acc[result.type] = [];
      }
      acc[result.type].push(result);
      return acc;
    }, {} as Record<CONTENT_TYPE, Content[]>);
  }

  private reset() {
    this.cursor = undefined;
    this.isLoading = false;
    this.searchResults = [];
    this.groupedResults = {};
  }
}

export const HSearchDialog = tsx
  .ofType<HSearchDialogProps, HSearchDialogEmits, HSearchDialogScopedSlots>()
  .convert(HSearchDialogRef);
