/**
 * This is auto generated file.
 * Do not edit !!!
 *
 * @see: scripts/dynamic-assets/icons/generator.js
 */

export const IconNames = [
  'accessibility',
  'airplane-outline',
  'amenity',
  'arrow-down',
  'arrow-head-left',
  'arrow-head-right',
  'arrow-left',
  'arrow-right',
  'baby',
  'badge-outline',
  'banner-arrow',
  'bed-fill',
  'bed',
  'bell-outline',
  'bus-outline',
  'calendar-month-outline',
  'car-outline',
  'cart-outline',
  'chat',
  'check-circle',
  'checkbox-brank-outline',
  'clock-outline',
  'close-circle',
  'close',
  'credit-card-blank-outline',
  'ellipsis-h-circle-outline',
  'ev-charger-outline',
  'exclamation-outline',
  'expand-arrows',
  'facebook',
  'gift-outline',
  'global',
  'green-pin',
  'hotel-outline',
  'image-alt-double',
  'image-alt',
  'info-circle-outline',
  'instagram',
  'jewelry-outline',
  'keyboard-arrow-down',
  'keyboard-arrow-left',
  'keyboard-arrow-right',
  'keyboard-arrow-up',
  'local-cafe-outline',
  'mail-outline',
  'map-marker-outline',
  'map',
  'massage',
  'meeting-room',
  'minus',
  'moon-outline',
  'onsen',
  'open-in-new',
  'parking-outline',
  'peoples',
  'pet-outline',
  'phone-outline',
  'plus-circle-outline',
  'plus',
  'pop-arrow-right',
  'question-outline',
  'restaurant-outline',
  'search',
  'shinkansen-outline',
  'shower',
  'skiing',
  'smlie-outline',
  'sort-toggle',
  'suitcase-outline',
  'swimming',
  'transparent-pin',
  'trash-can-outline',
  'triangle-down',
  'triangle-right',
  'tune',
  'twitter',
  'user-outline',
  'water-outline',
  'wifi',
  'youtube',
] as const;

export type IconName = typeof IconNames[number];