import { Middleware } from '@nuxt/types';

const OMO_THREE_SAPPORO_SUSUKINO_REGEXP = /^\/[^/]+\/hotels\/omo3sapporosusukino(?:\/|$)/;

const omoThreeSapporoSusukinoRedirectMiddleware: Middleware = (ctx) => {
  const { redirect, route } = ctx;
  const { path } = route;
  const lang = route.params.lang;
  if (OMO_THREE_SAPPORO_SUSUKINO_REGEXP.test(path)) {
    return redirect(301, `/${lang}/brands/omo/sp/OMOhokkaido/`);
  }
};

export default omoThreeSapporoSusukinoRedirectMiddleware;
