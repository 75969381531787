import * as tsx from 'vue-tsx-support';
import { PropType, CreateElement } from 'vue';
import { MODULE_MAPPINGS } from './moduleMappings';
import { SpecialPage as GfSpecialPage, NAVIGATION_PREFIX } from '~/schemes';

export const HSpecialPageModules = tsx.component({
  name: 'HSpecialPageModules',
  functional: true,

  props: {
    modules: {
      type: Array as PropType<GfSpecialPage['layout']['modules']>,
      required: true,
    },
  },

  render(h: CreateElement, { props, parent }) {
    return (
      <div staticClass="h-special-page-modules">
        {props.modules.map(
          ({ moduleId, properties, anchor, verticalSpacing }) => {
            const module = MODULE_MAPPINGS[moduleId];
            if (!module && (process.server || !parent.$env.isProduction)) {
              return parent.$logger.warn(
                `${moduleId} はGF側では削除された、もしくは表示する準備ができていません`,
              );
            }
            const node = module(h, properties as any, verticalSpacing);
            if (!node.data) node.data = {};
            if (!node.data.attrs) node.data.attrs = {};
            node.data.attrs.id = anchor
              ? `${NAVIGATION_PREFIX}${anchor}`
              : undefined;
            return node;
          },
        )}
      </div>
    );
  },
});
