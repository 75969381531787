import './HHotelIntroduction.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { HotelIntroductionListSize } from './schemes';
import {
  ExtractedHotelIntroductionDisplayProperties,
  toHotelIntroductionDisplayProperties,
} from './helpers';
import { HHotelIntroductionPlan } from './HHotelIntroductionPlan';
import { HBtn } from '~/components';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import {
  AvailableLanguage,
  HotelIntroductionModule,
  Hyperlink,
} from '~/schemes';

type ResolvedHotelIntroductionModule = ResolvedLanugageData<
  HotelIntroductionModule['properties'],
  AvailableLanguage
>;

/**
 * カスタムリンクを表すインターフェース。
 * CMSの該当なしで登録された空室検索や施設サイトの情報を定義
 */
interface CustomLink {
  /** リンク先のターゲットを指定 "_blank" or "_self" */
  targetValue: string;
  /** 遷移先のURL */
  href: string;
  /** リンクのボタンに表示されるテキスト */
  text: string;
}

export interface HHotelIntroductionProps {
  value: ResolvedHotelIntroductionModule;
  size?: HotelIntroductionListSize;
  disableContainer?: boolean;
}

export interface HHotelIntroductionEmits {}

export interface HHotelIntroductionScopedSlots {}

@Component<HHotelIntroductionRef>({
  name: 'HHotelIntroduction',
  render() {
    const {
      disableContainer,
      size,
      hotelIntroductionDisplayProperties,
      brandAddress,
      hotelName,
      isCatchCopy,
      isNormalText,
      isShortText,
      resolvedCustomSiteLink,
      resolvedCustomRoomSearchLink,
    } = this;

    const {
      images,
      catchCopy,
      text,
      shortText,
      actions,
      plans,
    } = hotelIntroductionDisplayProperties;

    return (
      <div
        staticClass="h-hotel-introduction-module"
        class={!disableContainer && 'h-container'}>
        <div staticClass="h-hotel-introduction-module__inner">
          {!!images && (
            <div
              staticClass="h-hotel-introduction-module__media"
              class={`h-hotel-introduction-module__media--${size}`}
              style={{
                '--sp-image': `url(${images.md})`,
                '--pc-image': `url(${images.lg})`,
              }}
            />
          )}
          <div staticClass="h-hotel-introduction-module__body">
            {!!brandAddress && (
              <address
                staticClass="h-hotel-introduction-module__brandAddress"
                class={`h-hotel-introduction-module__brandAddress--${size}`}>
                {brandAddress}
              </address>
            )}
            {!!hotelName && (
              <h3 staticClass="h-hotel-introduction-module__title">
                {hotelName}
              </h3>
            )}
            {isCatchCopy && (
              <div
                staticClass="h-hotel-introduction-module__catch-copy"
                class={`h-hotel-introduction-module__catch-copy--${size}`}>
                {catchCopy}
              </div>
            )}
            {isNormalText && (
              <div
                staticClass={'h-hotel-introduction-module__text'}
                class={`h-hotel-introduction-module__text--${size}`}
                v-wysiwyg={text}
              />
            )}
            {isShortText && (
              <div
                staticClass={'h-hotel-introduction-module__short-text'}
                v-wysiwyg={shortText}
              />
            )}
            {plans.length > 0 && (
              <div staticClass="h-hotel-introduction-module__plans">
                {plans.map((plan) => (
                  <HHotelIntroductionPlan
                    key={plan.key}
                    staticClass="h-hotel-introduction-module__plan"
                    value={plan}
                  />
                ))}
              </div>
            )}
            {actions.length > 0 && (
              <div staticClass="h-hotel-introduction-module__footer">
                {actions.map((action) => (
                  <HBtn
                    key={action.key}
                    staticClass="h-hotel-introduction-module__footer__action"
                    class={`h-hotel-introduction-module__footer__action--${size}`}
                    color="darken"
                    {...action.link}>
                    {action.label}
                  </HBtn>
                ))}
              </div>
            )}

            <div staticClass="h-hotel-introduction-module__footer">
              {resolvedCustomSiteLink.href && (
                <HBtn
                  staticClass="h-hotel-introduction-module__footer__action"
                  class={`h-hotel-introduction-module__footer__action--${size}`}
                  color="darken"
                  href={resolvedCustomSiteLink.href}
                  target={resolvedCustomSiteLink.targetValue}>
                  {resolvedCustomSiteLink.text}
                </HBtn>
              )}
              {resolvedCustomRoomSearchLink.href && (
                <HBtn
                  staticClass="h-hotel-introduction-module__footer__action"
                  class={`h-hotel-introduction-module__footer__action--${size}`}
                  color="darken"
                  href={resolvedCustomRoomSearchLink.href}
                  target={resolvedCustomRoomSearchLink.targetValue}>
                  {resolvedCustomRoomSearchLink.text}
                </HBtn>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
})
export class HHotelIntroductionRef
  extends Vue
  implements HHotelIntroductionProps {
  @Prop(Object) readonly value!: ResolvedHotelIntroductionModule;
  @Prop(String) readonly size!: HotelIntroductionListSize;
  @Prop(Boolean) readonly disableContainer!: boolean;

  /** 施設紹介表示用データ */
  get hotelIntroductionDisplayProperties(): ExtractedHotelIntroductionDisplayProperties {
    return toHotelIntroductionDisplayProperties(this.$parent, this.value);
  }

  /** ブランドサイト所在地 */
  get brandAddress(): string | undefined {
    const {
      displayBrandAddress,
      brandAddress,
    } = this.hotelIntroductionDisplayProperties;
    return (displayBrandAddress && brandAddress) || undefined;
  }

  /**
   * 施設名
   * - 施設情報が存在する場合は施設名称を取得し、該当なしの場合はフリー入力された値を取得
   */
  get hotelName(): string | undefined {
    const { hotel, freeText } = this.hotelIntroductionDisplayProperties;
    return (hotel && hotel.name) || freeText;
  }

  /**
   * 2カラム表示かどうかを判断する
   * - 3列または4列のスマートフォン表示
   */
  get isMobile2Columns(): boolean {
    const { size } = this;
    return this.isSP && (size === 'sm' || size === 'xs');
  }

  /** スマートフォンであればtrue */
  get isSP() {
    return this.$mq.match.narrow;
  }

  /**
   * キャッチコピーを使用するかどうかを判断する
   * - キャッチコピー登録済かつ「キャッチコピー＋施設紹介文」が選択されている状態
   */
  get isCatchCopy(): boolean {
    const { catchCopy, isText } = this.hotelIntroductionDisplayProperties;
    return !!catchCopy && isText;
  }

  /**
   * 通常の施設紹介文を使用するかどうかを判断する
   * - 施設紹介文登録済かつ「キャッチコピー＋施設紹介文」が選択されている状態
   */
  get isNormalText(): boolean {
    const { text, isText } = this.hotelIntroductionDisplayProperties;
    return !!text && isText;
  }

  /**
   * 施設紹介文ショートを使用するかどうかを判断する
   * - 施設紹介文ショート登録済かつ「施設紹介文(ショート)」が選択されている状態
   * - または表示が2カラムの場合
   */
  get isShortText(): boolean {
    const { shortText, isText } = this.hotelIntroductionDisplayProperties;
    return (!!shortText && !isText) || (!!shortText && this.isMobile2Columns);
  }

  /** 該当なしで設定された施設サイト情報 */
  get resolvedCustomSiteLink() {
    const { customSiteLink } = this.hotelIntroductionDisplayProperties;
    return this.getFormattedLink(customSiteLink);
  }

  /** 該当なしで設定された空室検索情報 */
  get resolvedCustomRoomSearchLink() {
    const { customRoomSearchLink } = this.hotelIntroductionDisplayProperties;
    return this.getFormattedLink(customRoomSearchLink);
  }

  /** リンク情報の整形 */
  private getFormattedLink(link: Hyperlink | undefined): CustomLink {
    const targetValue = link && link.blank ? '_blank' : '_self';
    const href = (link && link.url) || '';
    const text = (link && link.text) || '';
    return { targetValue, href, text };
  }
}

export const HHotelIntroduction = tsx
  .ofType<
    HHotelIntroductionProps,
    HHotelIntroductionEmits,
    HHotelIntroductionScopedSlots
  >()
  .convert(HHotelIntroductionRef);
