import { GetterTree, MutationTree } from 'vuex';
import { State as RootState } from './';
import { BookingActionSetting } from '~/schemes';

export interface State {
  bookingActionSettings: BookingActionSetting[];
}

export const state = (): State => ({
  bookingActionSettings: [],
});

export const getters: GetterTree<State, RootState> = {
  current(state): BookingActionSetting | null {
    return state.bookingActionSettings[0] || null;
  },
};

export const mutations: MutationTree<State> = {
  SET_BOOKING_ACTION_SETTING(
    state,
    bookingActionSetting: BookingActionSetting | null,
  ) {
    if (bookingActionSetting) {
      state.bookingActionSettings.unshift(bookingActionSetting);
    }
  },
  REMOVE_CURRENT_BOOKING_ACTION_SETTING(state) {
    if (state.bookingActionSettings) {
      state.bookingActionSettings = [];
    }
  },
};
